import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const SettingsMenuProfile: React.FC = () => {
  const location = useLocation();
  const [openTab, setOpenTab] = useState(1);

  const activeClasses = 'text-virtuspurple border-virtuspurple';
  const inactiveClasses = 'border-transparent';

  useEffect(() => {
    // Verifica a URL atual e define a aba correta com base nela
    if (location.pathname === '/Pages/ProfileSettingsPage') {
      setOpenTab(1);
    } else if (location.pathname === '/Pages/Configuracao') {
      setOpenTab(2);
    } else if (location.pathname === '/Pages/UsersAndTeams') {
      setOpenTab(3);
    }
  }, [location.pathname]);

  return (
    <ul className="flex flex-col gap-2">
      <li>
        <Link
          to="/Pages/ProfileSettingsPage"
          className={`relative flex items-center gap-2.5 py-2.5 px-5 font-medium duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-virtuspurple before:duration-300 before:ease-linear hover:bg-virtuspurple/5 hover:text-virtuspurple hover:before:h-full ${
            openTab === 1 ? activeClasses : inactiveClasses
          }`}
        >
          Perfil
        </Link>
      </li>
      <li>
        <Link
          to="/Pages/Configuracao"
          className={`relative flex items-center gap-2.5 py-2.5 px-5 font-medium duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-virtuspurple before:duration-300 before:ease-linear hover:bg-virtuspurple/5 hover:text-virtuspurple hover:before:h-full ${
            openTab === 2 ? activeClasses : inactiveClasses
          }`}
        >
          Empresa
        </Link>
      </li>
      <li>
        <Link
          to="/Pages/UsersAndTeams"
          className={`relative flex items-center gap-2.5 py-2.5 px-5 font-medium duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-virtuspurple before:duration-300 before:ease-linear hover:bg-virtuspurple/5 hover:text-virtuspurple hover:before:h-full ${
            openTab === 3 ? activeClasses : inactiveClasses
          }`}
        >
          Usuários e Equipes
        </Link>
      </li>
    </ul>
  );
};

export default SettingsMenuProfile;