import React, { useState } from 'react';

interface Automacao {
  id: string;
  name: string;
}

const automacoes: Automacao[] = [
  { id: 'Visualização da sessão', name: 'Visualização da sessão' },
  { id: 'Criação e edição de Automação', name: 'Criação e edição de Automação' },
  { id: 'Edição de Automação de outos usuários', name: 'Edição de Automação de outos usuários' },
];

const CheckboxInputGroup: React.FC<{
  id: string;
  isChecked: boolean;
  onChange: () => void;
}> = ({ id, isChecked, onChange }) => (
  <div>
    <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
      <input
        className="sr-only"
        type="checkbox"
        name="roleSelect"
        id={id}
        checked={isChecked}
        onChange={onChange}
      />
      <span
        className={`flex h-5 w-5 items-center justify-center rounded-full border ${
          isChecked ? 'border-primary' : 'border-body'
        }`}
      >
        <span
          className={`h-2.5 w-2.5 rounded-full bg-primary ${
            isChecked ? 'flex' : 'hidden'
          }`}
        ></span>
      </span>
      {id}
    </label>
  </div>
);

const SelectOptionAutomation: React.FC = () => {
  const [selectedAutomacoes, setSelectedAutomacoes] = useState<string[]>([]);

  const handleAutomacaoChange = (id: string) => {
    setSelectedAutomacoes((prevSelectedAutomacoes) => {
      if (prevSelectedAutomacoes.includes(id)) {
        // Deselect the language if it's already selected
        return prevSelectedAutomacoes.filter((lang) => lang !== id);
      } else {
        // Select the language if it's not selected
        return [...prevSelectedAutomacoes, id];
      }
    });
  };

  return (
    <div className="mb-6">
      <h3 className="mb-4.5 block text-sm font-medium text-azul_medio dark:text-white">
      Automação
      </h3>

      <div className="flex flex-col gap-2.5">
        {automacoes.map((automacao) => (
          <CheckboxInputGroup
            key={automacao.id}
            id={automacao.id}
            isChecked={selectedAutomacoes.includes(automacao.id)}
            onChange={() => handleAutomacaoChange(automacao.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectOptionAutomation;
