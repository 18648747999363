import React, { useState, useRef } from 'react';

const ModalNQ: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const modal = useRef<HTMLDivElement>(null);

  // Abrir/Fechar modal sem recarregar a página
  const toggleModal = (e: React.MouseEvent) => {
    e.preventDefault(); // Evita comportamentos padrão
    setModalOpen(!modalOpen);
  };

  const closeModal = (e: React.MouseEvent) => {
    e.preventDefault(); // Evita comportamentos padrão
    setModalOpen(false);
  };

  return (
    <div>
      <button
        onClick={toggleModal}
        type="button"
        className="rounded-md bg-primary px-9 py-3 font-medium text-white hover:bg-opacity-90"
      >
        Criar nova pergunta 
      </button>

      {modalOpen && (
        <div
          className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/90 px-4"
        >
          <div
            ref={modal}
            className="w-full max-w-lg rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark"
          >
            <h3 className="pb-2 text-xl font-bold text-black sm:text-2xl">
                Criar pergunta
            </h3>
            <div>
                  <label className="">Pergunta</label>
                  <input
                    type="text"
                    placeholder="Insira"
                    className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-3 px-5"
                  />
                </div>
                <div className="w-full mt-4">
              <h3>Resposta</h3>
              <textarea
                rows={6}
                placeholder="Insira "
                className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-4 text-black outline-none"
                style={{ resize: 'none', boxSizing: 'border-box', height: '120px' }}
              ></textarea>
            </div>

            <div className="flex flex-wrap gap-4 justify-center">
              <button
                onClick={closeModal}
                type="button"
                className="rounded border border-gray-300 bg-gray-100 px-4 py-2 text-center font-medium text-black transition hover:bg-gray-200 dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:bg-meta-1"
              >
                Cancelar
              </button>
              <button
                onClick={closeModal}
                type="button"
                className="rounded border border-primary bg-primary px-4 py-2 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Criar Pergunta
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalNQ;
