const DmaQualityControl = () => {
    return (
        
      <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-6 xl:gap-0">
          <div className="flex items-center justify-center gap-2 border-b border-stroke pb-5 dark:border-strokedark xl:border-b-0 xl:border-r xl:pb-0">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Interessados</p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 border-b border-stroke pb-5 dark:border-strokedark xl:border-b-0 xl:border-r xl:pb-0">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Reagendamentos</p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 border-b border-stroke pb-5 dark:border-strokedark sm:border-b-0 sm:pb-0 xl:border-r">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Em Negociação</p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 border-b border-stroke pb-5 dark:border-strokedark sm:border-b-0 sm:pb-0 xl:border-r">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Já Comprou</p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 border-b border-stroke pb-5 dark:border-strokedark sm:border-b-0 sm:pb-0 xl:border-r">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Sem Interesse</p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <div>
              <h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg">
                0
              </h4>
              <p className="text-sm font-medium">Caixa Postal</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
export default DmaQualityControl;
