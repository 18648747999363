// import { ProfileList } from '../../types/ProfileList';
// import ProductOne from '@images/product/product-01.png';
// import ProductTwo from '@images/product/product-02.png';
// import ProductThree from '@images/product/product-03.png';
// import ProductFour from '@images/product/product-04.png';
// import DropdownFour from '../Dropdowns/DropdownFour';

// const productData: ProfileList[] = [
//   {
//     profile: 'Super Admin',
//   },
//   {
//     profile: 'Admin',
//   },
//   {
//     profile: 'Vendedor',
//   },
//   {
//     profile: 'Operacional',
//   },
//   {
//     profile: 'Vendedor',
//   },

// ];

// const TableProfile = () => {
//   return (
//     <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//       <div className="overflow-hidden rounded-[10px]">
//         <div className="max-w-full overflow-x-auto ">
//             <div className="min-w-[1170px]">
//                 <div className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
//                     <div className="col-span-2 flex items-center">
//                     <p className="font-medium">Pefis</p>
//                     </div>
//                     <div className="col-span-1 items-center sm:flex">
//                     <p className="font-medium">Edição</p>
//                     </div>
//                 </div>

//                 {productData.map((product, key) => (
//                     <div
//                     className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
//                     key={key}
//                     >
//                         <div className="col-span-2 items-center sm:flex">
//                             <p className="text-sm text-black dark:text-white">
//                             {product.profile}
//                             </p>
//                         </div>

//                         <div className="col-span-1 flex items-center">
//                             <p className="font-medium"><DropdownFour /></p>
//                         </div>
//                     </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     </div>

//   );
// };

// export default TableProfile;

import { ProfileList } from '../../types/ProfileList';
import DropdownFour from '../Dropdowns/DropdownFour';

const productData: ProfileList[] = [
  {
    profile: 'Super Admin',
  },
  {
    profile: 'Admin',
  },
  {
    profile: 'Vendedor',
  },
  {
    profile: 'Operacional',
  },
  {
    profile: 'Vendedor',
  },
];

const TableProfile = () => {
  // Função para prevenir o comportamento padrão
  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault(); // Previne qualquer comportamento padrão como submit ou recarregamento
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto ">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
              <div className="col-span-1 items-center sm:flex">
                <p className="font-medium">Perfil</p>
              </div>

              <div className="col-span-1 flex items-center">
                <p className="font-medium">Edição</p>
              </div>
            </div>

            {productData.map((product, key) => (
              <div
                className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.profile}
                  </p>
                </div>

                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    {/* Wrapper com onClick que previne comportamento padrão */}
                    <div onClick={handleButtonClick}>
                      <DropdownFour />
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableProfile;
