import React from 'react';
import { CardItemProps } from '../types/cards';
import { Link } from 'react-router-dom';

const CardsIntegration: React.FC<CardItemProps> = ({
  cardTitle,
  cardContent,
  cardCategory,
  cardStatus,
}) => {
  // Verifique o valor de cardStatus
  console.log(cardStatus); // Certifique-se de que é "Em uso"

  return (
    <div className="rounded-lg  bg-white shadow-default dark: dark:bg-boxdark">
      <div className="rounded-t-lg p-5 px-7.5 dark:border-strokedark">
        {/* Categoria como botão */}
        <p className="inline-flex rounded-full bg-azul20 bg-opacity-20 py-1 px-3 text-sm font-medium text-azul20 ">
          {cardCategory || 'Categoria indefinida'} {/* Fallback para categoria */}
        </p>
        <div className="flex justify-between items-center mt-2.5">
          {/* Título do Card */}
          <h4 className="text-xl font-semibold text-black dark:text-white">
            {cardTitle}
          </h4>
          {/* Exibe o status somente se for "Em uso" */}
          {cardStatus && cardStatus.trim().toLowerCase() === 'em uso' && (
            <p className="inline-flex rounded bg-verde20 bg-opacity-20 py-1 px-2 text-sm font-medium text-verde20 ml-3">
              {cardStatus}
            </p>
          )}
        </div>
      </div>
      <div className="rounded-b-lg px-7.5 pt-6 pb-9">
        <p>{cardContent || 'Conteúdo indefinido'}</p> {/* Fallback para conteúdo */}
        {/* Botão baseado no status */}
        {cardStatus && cardStatus.trim().toLowerCase() === 'em uso' ? (
          <Link to="#">
            <button className="inline-flex items-center justify-center w-[105px] h-[40px] rounded bg-virtuspurple text-sm font-medium text-white hover:bg-opacity-90 mt-4">
              Editar
            </button>
          </Link>
        ) : (
          <Link to="#">
            <button className="inline-flex items-center justify-center w-[105px] h-[40px] rounded bg-primary text-sm font-medium text-white hover:bg-opacity-90 mt-4">
              Integrar
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CardsIntegration;
