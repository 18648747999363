import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';
import TableSeven from '../components/Tables/TableSeven';
import HeaderCampaign from '../components/Header/HeaderCampaign';

const Campaign = () => {
  return (
    <DefaultLayout>
      {/* <Breadcrumb pageName="Campanha" /> */}

      <HeaderCampaign />
      <div className="col-span-12 flex flex-wrap items-center justify-between gap-3 py-4"/>
      <div className="col-span-12 flex flex-col gap-10">
        <TableSeven />
      </div>
    </DefaultLayout>
  );
};

export default Campaign;
