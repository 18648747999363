import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// import React from 'react';
import { AuthProvider } from './components/AuthContext/AuthContext';
import ProtectedRoute from './components/AuthContext/ProtectedRoute';
// import PrivateRoute from './components/AuthContext/PrivateRoute';

import ECommerce from './pages/Dashboard/ECommerce';
import Configuracao from './pages/Pages/Configuracao';
import ProfileSettingsPage from './pages/Pages/ProfileSettingsPage';
// import Configuracao from './pages/UiElements/Configuracao';
import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import MarketingMixModeling from './pages/Analytics/MarketingMixModeling';
import Panels from './pages/Analytics/Panels';
import ComingSoon from './pages/Authentication/ComingSoon';
import ResetPassword from './pages/Authentication/ResetPassword';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import TwoStepVerification from './pages/Authentication/TwoStepVerification';
import UnderMaintenance from './pages/Authentication/UnderMaintenance';
import Calendar from './pages/Calendar';
import Campaign from './pages/Campaign';
import AdvancedChart from './pages/Chart/AdvancedChart';
import BasicChart from './pages/Chart/BasicChart';
import Analytics from './pages/Dashboard/Analytics';
import CRM from './pages/Dashboard/CRM';
import DMA from './pages/Dashboard/DMA';
import Marketing from './pages/Dashboard/Marketing';
import Stocks from './pages/Dashboard/Stocks';
import FormElements from './pages/Form/FormElements';
import FormLayout from './pages/Form/FormLayout';
import FormLayoutAddUser from './pages/Form/FormLayoutAddUser';
import FormLayoutAgentModel from './pages/Form/FormLayoutAgentModel';
import FormLayoutModalProfile from './pages/Form/FormLayoutModalProfile';
import FormLayoutProfile from './pages/Form/FormLayoutProfile';
import ProFormElements from './pages/Form/ProFormElements';
import ProFormLayout from './pages/Form/ProFormLayout';
import Inbox from './pages/Inbox';
import Invoice from './pages/Invoice';
import Automation from './pages/MarketingHub/Automation';
import {
	default as CampaignDashboard,
	default as CampaingDashboard,
} from './pages/MarketingHub/CampaignDashboard';
import CampaingList from './pages/MarketingHub/CampaingList';
import Messages from './pages/Messages';
import Mmm from './pages/Mmm';
import AgentCreation from './pages/Pages/AgentCreation';
import Agents from './pages/Pages/Agents';
import DataTables from './pages/Pages/DataTables';
import ErrorPage from './pages/Pages/ErrorPage';
import Faq from './pages/Pages/Faq';
import FileManager from './pages/Pages/FileManager';
import Integrations from './pages/Pages/Integrations';
import MailSuccess from './pages/Pages/MailSuccess';
import PricingTables from './pages/Pages/PricingTables';
import Settings from './pages/Pages/Settings';
import Teams from './pages/Pages/Teams';
import TermsConditions from './pages/Pages/TermsConditions';
import UsersAndTeams from './pages/Pages/UsersAndTeams';
import Profile from './pages/Profile';
import ProfilePreference from './pages/ProfilePreference';
import Leads from './pages/SalesHub/Leads';
import ProTables from './pages/Tables/ProTables';
import Tables from './pages/Tables/Tables';
import TaskKanban from './pages/Task/TaskKanban';
import TaskList from './pages/Task/TaskList';
import TecnisaAll from './pages/Tecnisa/TecnisaAll';
import TecnisaFacebook from './pages/Tecnisa/TecnisaFacebook';
import TecnisaGoogle from './pages/Tecnisa/TecnisaGoogle';
import Accordion from './pages/UiElements/Accordion';
import Alerts from './pages/UiElements/Alerts';
import Avatars from './pages/UiElements/Avatars';
import Badge from './pages/UiElements/Badge';
import Breadcrumbs from './pages/UiElements/Breadcrumbs';
import Buttons from './pages/UiElements/Buttons';
import ButtonsGroup from './pages/UiElements/ButtonsGroup';
import Cards from './pages/UiElements/Cards';
import Carousel from './pages/UiElements/Carousel';
import Dropdowns from './pages/UiElements/Dropdowns';
import Images from './pages/UiElements/Images';
import List from './pages/UiElements/List';
import Modals from './pages/UiElements/Modals';
import Notifications from './pages/UiElements/Notifications';
import Pagination from './pages/UiElements/Pagination';
import Popovers from './pages/UiElements/Popovers';
import Progress from './pages/UiElements/Progress';
import Spinners from './pages/UiElements/Spinners';
import Tabs from './pages/UiElements/Tabs';
import Tooltips from './pages/UiElements/Tooltips';
import Videos from './pages/UiElements/Videos';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <AuthProvider>
        <Routes>
          <Route
            path="/auth/signin"
            element={
              <>
                <PageTitle title="Signin | Virtus" />
                <SignIn />
              </>
            }
          />
          <Route
            path="/auth/signup"
            element={
              <>
                <PageTitle title="Signup | Virtus" />
                <SignUp />
              </>
            }
          />
          <Route
            path="/auth/reset-password"
            element={
              <>
                <PageTitle title="Reset Password | Virtus" />
                <ResetPassword />
              </>
            }
          />
          <Route
            path="/auth/two-step-verification"
            element={
              <>
                <PageTitle title="2 Step Verification | Virtus" />
                <TwoStepVerification />
              </>
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route
              // index
              path="/dashboard/ecommerce"
              element={
                <>
                  <PageTitle title="eCommerce Dashboard | Virtus" />
                  <ECommerce />
                </>
              }
            />

            <Route
              path="/dashboard/analytics"
              element={
                <>
                  <PageTitle title="Analytics Dashboard | Virtus" />
                  <Analytics />
                </>
              }
            />

            <Route
              // path="/dashboard/Agents"
              index
              element={
                <>
                  <PageTitle title="Agentes | Virtus" />
                  <Agents />
                </>
              }
            />
            <Route
              path="/dashboard/Criação de Agente"
              element={
                <>
                  <PageTitle title="Criação de Agente | Virtus" />
                  <AgentCreation />
                </>
              }
            />
            <Route
              path="/integracoes"
              element={
                <>
                  <PageTitle title="Criação de Agente | Virtus" />
                  <Integrations />
                </>
              }
            />
            <Route
              path="/dashboard/CampaignDashboard"
              element={
                <>
                  <PageTitle title="Campanhas Dashboard | Virtus" />
                  <CampaignDashboard />
                </>
              }
            />
            <Route
              path="/MarketingHub/Automation"
              element={
                <>
                  <PageTitle title="Campanhas Dashboard | Virtus" />
                  <Automation />
                </>
              }
            />
            <Route
              path="/MarketingHub/CampaingList"
              element={
                <>
                  <PageTitle title="Campanhas Dashboard | Virtus" />
                  <CampaingList />
                </>
              }
            />
            <Route
              path="/MarketingHub/CampaingDashboard"
              element={
                <>
                  <PageTitle title="Campanhas Dashboard | Virtus" />
                  <CampaingDashboard />
                </>
              }
            />
            <Route
              path="/Pages/Configuracao"
              element={
                <>
                  <PageTitle title="Analytics Dashboard | Virtus" />
                  <Configuracao />
                </>
              }
            />
            <Route
              path="/Pages/ProfileSettingsPage"
              element={
                <>
                  <PageTitle title="Analytics Dashboard | Virtus" />
                  <ProfileSettingsPage />
                </>
              }
            />
            <Route
              path="/Pages/UsersAndTeams"
              element={
                <>
                  <PageTitle title="Analytics Dashboard | Virtus" />
                  <UsersAndTeams />
                </>
              }
            />
            <Route
              path="/dashboard/marketing"
              element={
                <>
                  <PageTitle title="Marketing Dashboard | Virtus" />
                  <Marketing />
                </>
              }
            />
            <Route
              path="/dashboard/crm"
              element={
                <>
                  <PageTitle title="CRM Dashboard | Virtus" />
                  <CRM />
                </>
              }
            />
            <Route
              path="/dashboard/dma"
              element={
                <>
                  <PageTitle title="DMA Dashboard | Virtus" />
                  <DMA />
                </>
              }
            />
            <Route
              path="/dashboard/stocks"
              element={
                <>
                  <PageTitle title="Stocks Dashboard | Virtus" />
                  <Stocks />
                </>
              }
            />
            <Route
              path="/calendar"
              element={
                <>
                  <PageTitle title="Calendar | Virtus" />
                  <Calendar />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <PageTitle title="Profile | Virtus" />
                  <Profile />
                </>
              }
            />
            <Route
              path="/ProfilePreference"
              element={
                <>
                  <PageTitle title="Perfil e Preferências | Virtus" />
                  <ProfilePreference />
                </>
              }
            />

            <Route
              path="/mmm"
              element={
                <>
                  <PageTitle title="Profile | Virtus" />
                  <Mmm />
                </>
              }
            />
            <Route
              path="/campanha"
              element={
                <>
                  <PageTitle title="Profile | Virtus" />
                  <Campaign />
                </>
              }
            />
            <Route
              path="/tasks/task-list"
              element={
                <>
                  <PageTitle title="Task List | Virtus" />
                  <TaskList />
                </>
              }
            />
            <Route
              path="/tasks/task-kanban"
              element={
                <>
                  <PageTitle title="Task Kanban | Virtus" />
                  <TaskKanban />
                </>
              }
            />
            <Route
              path="/SalesHub/Leads"
              element={
                <>
                  <PageTitle title="Leads | Virtus" />
                  <Leads />
                </>
              }
            />
            <Route
              path="/Analytics/MarketingMixModeling"
              element={
                <>
                  <PageTitle title="Marketing Mix Modeling | Virtus" />
                  <MarketingMixModeling />
                </>
              }
            />
            <Route
              path="/Analytics/Panels"
              element={
                <>
                  <PageTitle title="Paineis | Virtus" />
                  <Panels />
                </>
              }
            />
            <Route
              path="/tecnisa/tecnisa-all"
              element={
                <>
                  <PageTitle title="tecnisa | Dashboard Total" />
                  <TecnisaAll />
                </>
              }
            />
            <Route
              path="/tecnisa/tecnisa-google"
              element={
                <>
                  <PageTitle title="tecnisa | Dashboard Google" />
                  <TecnisaGoogle />
                </>
              }
            />
            <Route
              path="/tecnisa/tecnisa-facebook"
              element={
                <>
                  <PageTitle title="tecnisa | Dashboard Facebook" />
                  <TecnisaFacebook />
                </>
              }
            />
            <Route
              path="/forms/form-elements"
              element={
                <>
                  <PageTitle title="Form Elements | Virtus" />
                  <FormElements />
                </>
              }
            />
            <Route
              path="/forms/pro-form-elements"
              element={
                <>
                  <PageTitle title="Pro Form Elements | Virtus" />
                  <ProFormElements />
                </>
              }
            />
            <Route
              path="/forms/form-layout"
              element={
                <>
                  <PageTitle title="Form Layout | Virtus" />
                  <FormLayout />
                </>
              }
            />
            <Route
              path="/agent/modelo"
              element={
                <>
                  <PageTitle title="Modelo | Virtus" />
                  <FormLayoutAgentModel />
                </>
              }
            />
            <Route
              path="/forms/form-layout-add-user"
              element={
                <>
                  <PageTitle title="Form Layout | Virtus" />
                  <FormLayoutAddUser />
                </>
              }
            />
            <Route
              path="/forms/form-layout-profile"
              element={
                <>
                  <PageTitle title="Form Layout profile | Virtus" />
                  <FormLayoutProfile />
                </>
              }
            />
            <Route
              path="/forms/form-layout-modal-profile"
              element={
                <>
                  <PageTitle title="Form Layout profile | Virtus" />
                  <FormLayoutModalProfile />
                </>
              }
            />
            <Route
              path="/forms/pro-form-layout"
              element={
                <>
                  <PageTitle title="Pro Form Layout | Virtus" />
                  <ProFormLayout />
                </>
              }
            />
            <Route
              path="/tables/tables"
              element={
                <>
                  <PageTitle title="Tables | Virtus" />
                  <Tables />
                </>
              }
            />
            <Route
              path="/tables/pro-tables"
              element={
                <>
                  <PageTitle title="Pro Tables | Virtus" />
                  <ProTables />
                </>
              }
            />
            <Route
              path="/tables/pro-tables"
              element={
                <>
                  <PageTitle title="Tables | Virtus" />
                  <Tables />
                </>
              }
            />
            <Route
              path="/pages/settings"
              element={
                <>
                  <PageTitle title="Settings | Virtus" />
                  <Settings />
                </>
              }
            />
            <Route
              path="/pages/file-manager"
              element={
                <>
                  <PageTitle title="File Manager | Virtus" />
                  <FileManager />
                </>
              }
            />
            <Route
              path="/pages/data-tables"
              element={
                <>
                  <PageTitle title="Data Tables | Virtus" />
                  <DataTables />
                </>
              }
            />
            <Route
              path="/pages/pricing-tables"
              element={
                <>
                  <PageTitle title="Pricing Tables | Virtus" />
                  <PricingTables />
                </>
              }
            />
            <Route
              path="/pages/error-page"
              element={
                <>
                  <PageTitle title="Error Page | Virtus" />
                  <ErrorPage />
                </>
              }
            />
            <Route
              path="/pages/faq"
              element={
                <>
                  <PageTitle title="Faq's | Virtus" />
                  <Faq />
                </>
              }
            />
            <Route
              path="/pages/team"
              element={
                <>
                  <PageTitle title="Terms & Conditions | Virtus" />
                  <Teams />
                </>
              }
            />
            <Route
              path="/pages/terms-conditions"
              element={
                <>
                  <PageTitle title="Terms & Conditions | Virtus" />
                  <TermsConditions />
                </>
              }
            />
            <Route
              path="/pages/mail-success"
              element={
                <>
                  <PageTitle title="Mail Success | Virtus" />
                  <MailSuccess />
                </>
              }
            />
            <Route
              path="/messages"
              element={
                <>
                  <PageTitle title="Messages | Virtus" />
                  <Messages />
                </>
              }
            />
            <Route
              path="/inbox"
              element={
                <>
                  <PageTitle title="Inbox | Virtus" />
                  <Inbox />
                </>
              }
            />
            <Route
              path="/invoice"
              element={
                <>
                  <PageTitle title="Invoice | Virtus" />
                  <Invoice />
                </>
              }
            />
            <Route
              path="/chart/basic-chart"
              element={
                <>
                  <PageTitle title="Basic Chart | Virtus" />
                  <BasicChart />
                </>
              }
            />
            <Route
              path="/chart/advanced-chart"
              element={
                <>
                  <PageTitle title="Advanced Chart | Virtus" />
                  <AdvancedChart />
                </>
              }
            />
            <Route
              path="/ui/accordion"
              element={
                <>
                  <PageTitle title="Accordion | Virtus" />
                  <Accordion />
                </>
              }
            />
            <Route
              path="/ui/alerts"
              element={
                <>
                  <PageTitle title="Alerts |  Virtus" />
                  <Alerts />
                </>
              }
            />
            <Route
              path="/ui/avatars"
              element={
                <>
                  <PageTitle title="Avatars | Virtus" />
                  <Avatars />
                </>
              }
            />
            <Route
              path="/ui/badge"
              element={
                <>
                  <PageTitle title="Badge | Virtus" />
                  <Badge />
                </>
              }
            />
            <Route
              path="/ui/breadcrumbs"
              element={
                <>
                  <PageTitle title="Breadcrumbs | Virtus" />
                  <Breadcrumbs />
                </>
              }
            />
            <Route
              path="/ui/buttons"
              element={
                <>
                  <PageTitle title="Buttons | Virtus" />
                  <Buttons />
                </>
              }
            />
            <Route
              path="/ui/buttons-group"
              element={
                <>
                  <PageTitle title="Buttons Groupo | Virtus" />
                  <ButtonsGroup />
                </>
              }
            />
            <Route
              path="/ui/cards"
              element={
                <>
                  <PageTitle title="Cards | Virtus" />
                  <Cards />
                </>
              }
            />
            <Route
              path="/ui/carousel"
              element={
                <>
                  <PageTitle title="Carousel | Virtus" />
                  <Carousel />
                </>
              }
            />
            <Route
              path="/ui/dropdowns"
              element={
                <>
                  <PageTitle title="Dropdowns | Virtus" />
                  <Dropdowns />
                </>
              }
            />
            <Route
              path="/ui/images"
              element={
                <>
                  <PageTitle title="Images | Virtus" />
                  <Images />
                </>
              }
            />
            <Route
              path="/ui/list"
              element={
                <>
                  <PageTitle title="List | Virtus" />
                  <List />
                </>
              }
            />
            <Route
              path="/ui/modals"
              element={
                <>
                  <PageTitle title="Modals | Virtus" />
                  <Modals />
                </>
              }
            />
            <Route
              path="/ui/notifications"
              element={
                <>
                  <PageTitle title="Notifications | Virtus" />
                  <Notifications />
                </>
              }
            />
            <Route
              path="/ui/pagination"
              element={
                <>
                  <PageTitle title="Pagination | Virtus" />
                  <Pagination />
                </>
              }
            />
            <Route
              path="/ui/popovers"
              element={
                <>
                  <PageTitle title="Popovers | Virtus" />
                  <Popovers />
                </>
              }
            />
            <Route
              path="/ui/progress"
              element={
                <>
                  <PageTitle title="Progress | Virtus" />
                  <Progress />
                </>
              }
            />
            <Route
              path="/ui/spinners"
              element={
                <>
                  <PageTitle title="Spinners | Virtus" />
                  <Spinners />
                </>
              }
            />
            <Route
              path="/ui/tabs"
              element={
                <>
                  <PageTitle title="Tabs | Virtus" />
                  <Tabs />
                </>
              }
            />
            <Route
              path="/ui/tooltips"
              element={
                <>
                  <PageTitle title="Tooltips | Virtus" />
                  <Tooltips />
                </>
              }
            />
            <Route
              path="/ui/videos"
              element={
                <>
                  <PageTitle title="Videos | Virtus" />
                  <Videos />
                </>
              }
            />
            <Route
              path="/auth/coming-soon"
              element={
                <>
                  <PageTitle title="Coming Soon | Virtus" />
                  <ComingSoon />
                </>
              }
            />
            <Route
              path="/auth/under-maintenance"
              element={
                <>
                  <PageTitle title="Under Maintenance | Virtus" />
                  <UnderMaintenance />
                </>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to="/auth/signin" />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
