import { useState } from 'react';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';
// import userOne from "@images/user/user-01.png"
import ModalTeam from '../../components/Modals/ModalTeam';
import TableTeam from '../../components/Tables/TableTeam';

const FormLayoutTeam = () => {
  const [openTab, setOpenTab] = useState(2);
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Crie e personalize perfis de acesso às funcionalidades da plataforma
          pelos usuários.
        </h3>

        <form action="#">
          <div className="px-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="col-span-6 xl:col-span-6">
                <TableTeam />
              </div>
            </div>

            <ModalTeam />
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutTeam;
