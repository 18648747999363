import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import Settings from '../../pages/Pages/Settings';
// import SelectGroupTwo from '../Forms/SelectGroup/SelectGroupTwo';
import FormLayoutProfile from '../../pages/Form/FormLayoutProfile';
import FormLayoutAccont from '../../pages/Form/FormLayoutAccont';
import FormLayoutSecurity from '../../pages/Form/FormLayoutSecurity';
import FormLayoutEmailCalendar from '../../pages/Form/FormLayoutEmailCalendar';
import FormLayoutNotification from '../../pages/Form/FormLayoutNotification';

const TabProfile: React.FC = () => {
  const [openTab, setOpenTab] = useState(1);

  const activeClasses = 'text-virtuspurple border-virtuspurple';
  const inactiveClasses = 'border-transparent';

  return (
    <div className="rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm text-azul_escurofont-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 1 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(1)}
        >
          Dados Pessoais
        </Link>
        {/* <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 2 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(2)}
        >
          Email e Calendário
        </Link> */}
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 3 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(3)}
        >
          Notificações
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 4 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(4)}
        >
          Segurança
        </Link>
      </div>

      <div>
        <div
          className={`leading-relaxed ${openTab === 1 ? 'block' : 'hidden'}`}
        >
            <FormLayoutProfile/>
        </div>
        {/* <div
          className={`leading-relaxed ${openTab === 2 ? 'block' : 'hidden'}`}
        >
            <FormLayoutEmailCalendar/>
        </div> */}
        <div
          className={`leading-relaxed ${openTab === 2 ? 'block' : 'hidden'}`}
        >
          <FormLayoutAccont/>
        </div>
        <div
          className={`leading-relaxed ${openTab === 3 ? 'block' : 'hidden'}`}
        >
          <FormLayoutNotification/>
        </div>
        <div
          className={`leading-relaxed ${openTab === 4 ? 'block' : 'hidden'}`}
        >
          <FormLayoutSecurity/>
        </div>
      </div>
    </div>
  );
};

export default TabProfile
