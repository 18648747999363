import { useState } from 'react';
import ModalAddProfileList from '../../components/Modals/ModalAddProfileList';
import TableProfile from '../../components/Tables/TableProfile';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';
// import ModalTeam from '../../components/Modals/ModalTeam';

const FormUsersTeamsProfile = () => {
  const [openTab, setOpenTab] = useState(2);
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Crie e personalize perfis de acesso às funcionalidades da plataforma
          pelos usuários.
        </h3>

        <form action="#">
          <div className="px-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="col-span-6 xl:col-span-6">
                <TableProfile />
              </div>
            </div>

            <ModalAddProfileList />

            {/* 
                <Link
                  to="/forms/form-layout-modal-profile"
                  className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Adicionar Perfil
                </Link> */}
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormUsersTeamsProfile;
