import React from 'react';

// const DmaFooter: React.FC = () => {

const DmaFooter: React.FC = () => {
  return (
    <div className="col-span-12">
      <div className="rounded-sm border border-stroke bg-white py-3 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex justify-between gap-2.5 px-6 py-3 hover:bg-gray-2 dark:hover:bg-meta-4 sm:items-center sm:justify-start">
          <div className="flex items-center gap-5.5 sm:w-5/12 xl:w-3/12">
            <div className="hidden h-14 w-full max-w-14 items-center justify-center rounded-full border border-stroke bg-gray text-black-2 dark:border-strokedark dark:bg-graydark dark:text-white sm:flex">
              <img src="/src/images/brand/dma.png" alt="Brand"></img>
            </div>

            <p className="font-medium text-black dark:text-white">
              {/* JEEP ROMA 13.04.2024 */}
            </p>
          </div>

          <div className="hidden sm:block sm:w-4/12 xl:w-2/12">
            <p className="font-medium text-black dark:text-white">
              {/* File size: 455KB */}
            </p>
          </div>

          <div className="hidden w-5/12 xl:block">
            <p className="font-medium text-black dark:text-white">
              {/* Uploaded on: 25 Nov, 2025 */}
            </p>
          </div>

          <div className="text-right sm:w-3/12 xl:w-2/12">
            <button className="inline-flex rounded bg-primary px-3 py-1 font-medium text-white hover:bg-opacity-90 sm:px-6 sm:py-2.5">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmaFooter;
