import React from 'react';
import { Link } from 'react-router-dom';

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
}

const PaginationThree: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="p-4 sm:p-6 xl:p-7.5">
      <nav>
        <ul className="flex flex-wrap items-center gap-2">
          <li>
            <Link
              to="#"
              className={`flex items-center justify-center rounded py-1.5 px-3 font-medium ${currentPage === 1 ? 'text-gray-400' : 'hover:bg-primary hover:text-white'}`}
              onClick={() => paginate(currentPage - 1)}
            >
              Anterior
            </Link>
          </li>
          {pageNumbers.map((number) => (
            <li key={number}>
              <Link
                to="#"
                className={`flex items-center justify-center rounded py-1.5 px-3 font-medium ${currentPage === number ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}
                onClick={() => paginate(number)}
              >
                {number}
              </Link>
            </li>
          ))}
          <li>
            <Link
              to="#"
              className={`flex items-center justify-center rounded py-1.5 px-3 font-medium ${currentPage === pageNumbers.length ? 'text-gray-400' : 'hover:bg-primary hover:text-white'}`}
              onClick={() => paginate(currentPage + 1)}
            >
              Próximo
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationThree;
