import React from 'react';
import MmmSelectGroup from '../MmmComponents/MmmSelectGroup/MmmSelectGroup';
// import BrandOne from '@images/brand/brand-07.svg';

const HeaderCampaign: React.FC = () => {
  return (
    <div className="col-span-12">
      <div className="rounded-sm border border-stroke bg-white py-3 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex justify-between gap-2.5 px-6 py-3 hover:bg-gray-2 dark:hover:bg-meta-4 sm:items-center sm:justify-start">
          <div className="text-right sm:w-3/12 xl:w-10/12">
            <MmmSelectGroup />
          </div>
          <div className="text-right sm:w-3/12 xl:w-10/12">
            <MmmSelectGroup />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCampaign;
