import React, { useState } from 'react';

const SelectGroupFonte: React.FC<{ onChange: (value: string) => void }> = ({ onChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedOption(value);
    onChange(value); // Chama a função passada na prop
  };

  return (
    <div className="mb-4.5">
      <label className="mb-2.5 block text-black dark:text-white"> Fonte </label>
      <div className="font-galano relative z-20 bg-transparent dark:bg-form-input">
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        >
          <option value="" disabled>
            Selecione
          </option>
          <option value="URL">URL</option>
          <option value="Banco de Dados">Banco de Dados</option>
          <option value="API">API</option>
          <option value="Webhook">Webhook</option>
          <option value="Arquivo">Arquivo</option>
          <option value="Perguntas e respostas">Perguntas e respostas</option>
          <option value="Prompt">Prompt</option>
        </select>
      </div>
    </div>
  );
};

export default SelectGroupFonte;
