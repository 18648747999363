// import { ProductUserList } from '../../types/productUserList';
// import ProductOne from '@images/product/product-01.png';
// import ProductTwo from '@images/product/product-02.png';
// import ProductThree from '@images/product/product-03.png';
// import ProductFour from '@images/product/product-04.png';
// import DropdownFour from '../Dropdowns/DropdownFour';

// const productData: ProductUserList[] = [
//   {
//     image: ProductOne,
//     name: 'Breno Marinho Sepúlveda',
//     profile: 'Super Admin',
//     team: 'Proprietario da Conta',
//     status: 'Ativo',
//   },
//   {
//     image: ProductTwo,
//     name: 'Fábio Luan Aragão Sobrinho',
//     profile: 'Admin',
//     team: 'Gestores',
//     status: 'Ativo',
//   },
//   {
//     image: ProductThree,
//     name: 'Maitê Galvão de Almeida',
//     profile: 'Vendedor',
//     team: 'Comercial',
//     status: 'Inativo',
//   },
//   {
//     image: ProductFour,
//     name: 'Bárbara Jpyce de Guerra',
//     profile: 'Operacional',
//     team: 'Marketing',
//     status: 'Ativo',
//   },
//   {
//     image: ProductFour,
//     name: 'Lucas Branco de torquato',
//     profile: 'Vendedor',
//     team: 'Comercial',
//     status: 'Ativo',
//   },

// ];

// const TableUsers = () => {
//   return (
//     <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
//       <div className="overflow-hidden rounded-[10px]">
//         <div className="max-w-full overflow-x-auto ">
//             <div className="min-w-[1170px]">
//                 <div className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
//                     <div className="col-span-2 flex items-center">
//                     <p className="font-medium">Nome</p>
//                     </div>
//                     <div className="col-span-1 items-center sm:flex">
//                     <p className="font-medium">Perfil</p>
//                     </div>
//                     <div className="col-span-1 flex items-center">
//                     <p className="font-medium">Equipe</p>
//                     </div>
//                     <div className="col-span-1 flex items-center">
//                     <p className="font-medium">Status</p>
//                     </div>
//                     <div className="col-span-1 flex items-center">
//                     <p className="font-medium">Edição</p>
//                     </div>
//                 </div>

//                 {productData.map((product, key) => (
//                     <div
//                     className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
//                     key={key}
//                     >
//                         <div className="col-span-2 items-center sm:flex">
//                             <p className="text-sm text-black dark:text-white">
//                             {product.name}
//                             </p>
//                         </div>
//                         <div className="col-span-1 items-center sm:flex">
//                             <p className="text-sm text-black dark:text-white">
//                             {product.profile}
//                             </p>
//                         </div>
//                         <div className="col-span-1 flex items-center">
//                             <p className="text-sm text-black dark:text-white">
//                             {product.team}
//                             </p>
//                         </div>

//                         <div className="col-span-1 flex items-center">
//                             <p
//                               className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
//                                 product.status === 'Ativo'
//                                   ? 'bg-success text-success'
//                                   : product.status === 'Inativo'
//                                   ? 'bg-danger text-danger'
//                                   : 'bg-warning text-warning'
//                                 }`}
//                                 >
//                                   {product.status}
//                             </p>
//                         </div>

//                         <div className="col-span-1 flex items-center">
//                             <p className="font-medium"><DropdownFour /></p>
//                         </div>
//                     </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     </div>

//   );
// };

// export default TableUsers;

import ProductOne from '@images/product/product-01.png';
import ProductTwo from '@images/product/product-02.png';
import ProductThree from '@images/product/product-03.png';
import ProductFour from '@images/product/product-04.png';
import { ProductUserList } from '../../types/productUserList';
import DropdownFour from '../Dropdowns/DropdownFour';

const productData: ProductUserList[] = [
  {
    image: ProductOne,
    name: 'Breno Marinho Sepúlveda',
    profile: 'Super Admin',
    team: 'Proprietario da Conta',
    status: 'Ativo',
    project: 'Todos',
    email: 'breno@email.com',
  },
  {
    image: ProductTwo,
    name: 'Fábio Luan Aragão Sobrinho',
    profile: 'Admin',
    team: 'Gestores',
    status: 'Ativo',
    project: 'Todos',
    email: 'breno@email.com',
  },
  {
    image: ProductThree,
    name: 'Maitê Galvão de Almeida',
    profile: 'Vendedor',
    team: 'Comercial',
    status: 'Inativo',
    project: 'Facebook, Tesla',
    email: 'breno@email.com',
  },
  {
    image: ProductFour,
    name: 'Bárbara Jpyce de Guerra',
    profile: 'Operacional',
    team: 'Marketing',
    status: 'ativo',
    project: 'Microsoft, Tesla, Facebook',
    email: 'breno@email.com',
  },
  {
    image: ProductFour,
    name: 'Lucas Branco de torquato',
    profile: 'Vendedor',
    team: 'Comercial',
    status: 'Ativo',
    project: 'Apple',
    email: 'breno@email.com',
  },
];

const TableUsers = () => {
  // Função para prevenir o comportamento padrão
  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault(); // Previne qualquer comportamento padrão como submit ou recarregamento
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto ">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Nome</p>
              </div>
              <div className="col-span-1 items-center sm:flex">
                <p className="font-medium">Email</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Projeto</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Perfil</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Status</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Edição</p>
              </div>
            </div>

            {productData.map((product, key) => (
              <div
                className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.name}
                  </p>
                </div>
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.email}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="text-sm text-black dark:text-white">
                    {product.project}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="text-sm text-black dark:text-white">
                    {product.profile}
                  </p>
                </div>

                <div className="col-span-1 flex items-center">
                  <p
                    className={`inline-flex rounded-full bg-opacity-10 px-3 py-1 text-sm font-medium ${
                      product.status === 'Ativo'
                        ? 'bg-success text-success'
                        : product.status === 'Inativo'
                        ? 'bg-danger text-danger'
                        : 'bg-warning text-warning'
                    }`}
                  >
                    {product.status}
                  </p>
                </div>

                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    {/* Wrapper com onClick que previne comportamento padrão */}
                    <div onClick={handleButtonClick}>
                      <DropdownFour />
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableUsers;
