import React, { useState } from 'react';
import { abilityList } from '../../types/abilityList';
import DropdownFour from '../Dropdowns/DropdownFour';
import PaginationThree from '../Paginations/paginationKn';

const productData: abilityList[] = [
  { name: '', tipo: 'Customizado' },
  { name: '', tipo: 'Encaminhamento' },
  { name: '', tipo: 'Iniciar Campanha' },
  { name: '', tipo: 'Responder Whatsapp' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
  { name: '', tipo: 'customizado' },
];

const DataTableAbility = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  // Lógica para selecionar itens da página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto ">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
              <div className="col-span-3 flex items-center">
                <p className="font-medium">Nome</p>
              </div>
              <div className="col-span-2 items-center sm:flex">
                <p className="font-medium">Tipo</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Edição</p>
              </div>
            </div>

            {currentItems.map((product, key) => (
              <div
                className="grid grid-cols-8 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-3 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.name}
                  </p>
                </div>
                <div className="col-span-2 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.tipo}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    <div onClick={handleButtonClick}>
                      <DropdownFour />
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Componente de Paginação */}
      <PaginationThree
        itemsPerPage={itemsPerPage}
        totalItems={productData.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default DataTableAbility;
