import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import CardsIntegration from '../../components/Cards/CardsIntegration';
import SelectGroupAgentStatus from '../../components/Forms/SelectGroup/SelectGroupAgentStatus';
import SelectGroupIntegrationsCategory from '../../components/Forms/SelectGroup/SelectGroupIntegrationsCategory';
import ModalIntegration from '../../components/Modals/ModalIntegration';
import DefaultLayout from '../../layout/DefaultLayout';

type IntegrationsData = {
  name: string;
  category: string;
  status: string;
  description: string;
};

const Integrations: React.FC = () => {
  const IntegrationsData: IntegrationsData[] = [
    {
      name: 'Facebook',
      category: 'Social Media',
      status: 'Sem uso',
      description:
        'Rede Social com gestão de conteúdos e anúncios pagos da Meta',
    },
    {
      name: 'Google Calendar',
      category: 'Produtividade',
      status: 'Sem uso',
      description: 'Gestão de compromissos e reuniões do Google',
    },
    {
      name: 'PayPal',
      category: 'Financeira',
      status: 'Sem uso',
      description: 'Gateway de pagamento online do PayPal',
    },
    {
      name: 'WhatsApp',
      category: 'Mensageiro',
      status: 'Em uso',
      description: 'Mensageiro online para telefones móveis e desktop da Meta',
    },
    {
      name: 'Google Sheets',
      category: 'Produtividade',
      status: 'Sem uso',
      description: 'Planilha online para gestão de dados e fórmulas do Google.',
    },
    {
      name: 'App custom exemplo',
      category: 'Customizado',
      status: 'Em uso',
      description: 'Descrição de uso...',
    },
  ];

  const [searchNome, setSearchNome] = useState('');
  const [searchCategoria, setSearchCategoria] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const filteredData = IntegrationsData.filter(
    (Integration) =>
      Integration.name.toLowerCase().includes(searchNome.toLowerCase()) &&
      (searchCategoria === '' || Integration.category === searchCategoria) &&
      (searchStatus === '' || Integration.status === searchStatus)
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Integrações" />

      <div className="mb-5 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 items-center ">
        <input
          type="text"
          placeholder="Pesquisar por nome"
          value={searchNome}
          onChange={(e) => setSearchNome(e.target.value)}
          className="mt-4 relative z-20 w-full appearance-none rounded border border-stroke bg-white py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-grafite_ dark:bg-form-input dark:focus:border-grafite_ ${
            isOptionSelected ? 'text-black dark:text-white' : ''"
        />

        <SelectGroupIntegrationsCategory
          selectedOption={searchCategoria}
          onCategoryChange={(value: string) => setSearchCategoria(value)}
        />

        <SelectGroupAgentStatus 
          selectedOption={searchStatus}
          onStatusChange={(value: string) => setSearchStatus(value)}
        />

        {/* <div className="flex justify-end">
        <ModalIntegration/>
        </div> */}
        <div className="mt-4">
          <ModalIntegration />
        </div>
        
      </div>

      <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-3">
        {paginatedData.map((Integration, index) => (
          <CardsIntegration
            key={index}
            cardTitle={Integration.name}
            cardStatus={Integration.status}
            cardCategory={Integration.category}
            cardContent={Integration.description}
          />
        ))}
      </div>

      <div className="mt-5 flex items-center justify-between">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="rounded-md border p-2"
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="rounded-md border p-2"
        >
          Próxima
        </button>
      </div>
    </DefaultLayout>
  );
};

export default Integrations;
