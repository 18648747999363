import { useState } from 'react';
import DataTableKnowledge from '../../components/DataTables/DataTableKnowledge';
import ModalAgentKn from '../../components/Modals/ModalAgentKn';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';

const FormLayoutAgentKnowledge = () => {
  const [openTab, setOpenTab] = useState(2);
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-medium text-black dark:text-white">
          Conhecimentos do agente
        </h3>

        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Defina e treine a base de conhecimento do agente.
        </h3>

        <form action="#">
          <div className="px-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="col-span-6 xl:col-span-6">
                <DataTableKnowledge />
              </div>
            </div>
            {/* <Link
                  to="/forms/form-layout-modal-profile"
                  className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Criar Conhecimento
                </Link> */}

            <ModalAgentKn />
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutAgentKnowledge;
