import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FormLayoutAgentProfile from '../../pages/Form/FormLayoutAgentProfile';
import FormLayoutAgentKnowledge from '../../pages/Form/FormLayoutAgentKnowledge';
import FormLayoutAgentAbility from '../../pages/Form/FormLayoutAgentAbility';
import FormLayoutAgentTools from '../../pages/Form/FormLayoutAgentTools';
import FormAgentTraining from '../../pages/Form/FormAgentTraining';

const TabAgentProfile: React.FC = () => {
  const [openTab, setOpenTab] = useState(1);

  const activeClasses = 'text-virtuspurple border-virtuspurple';
  const inactiveClasses = 'border-transparent';

  const nextTab = () => {
    setOpenTab((prevTab) => (prevTab < 5 ? prevTab + 1 : prevTab));
  };

  const previousTab = () => {
    setOpenTab((prevTab) => (prevTab > 1 ? prevTab - 1 : prevTab));
  };

  return (
    <div className="rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      {/* Cabeçalho com abas */}
      <div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 1 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(1)}
        >
          Perfil
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 2 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(2)}
        >
          Conhecimentos
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 3 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(3)}
        >
          Habilidades
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 4 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(4)}
        >
          Ferramentas
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 5 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(5)}
        >
          Treinar
        </Link>
      </div>

      {/* Conteúdo das abas */}
      <div>
        <div className={`leading-relaxed ${openTab === 1 ? 'block' : 'hidden'}`}>
          <FormLayoutAgentProfile />
        </div>
        <div className={`leading-relaxed ${openTab === 2 ? 'block' : 'hidden'}`}>
          <FormLayoutAgentKnowledge />
        </div>
        <div className={`leading-relaxed ${openTab === 3 ? 'block' : 'hidden'}`}>
          <FormLayoutAgentAbility />
        </div>
        <div className={`leading-relaxed ${openTab === 4 ? 'block' : 'hidden'}`}>
          <FormLayoutAgentTools />
        </div>
        <div className={`leading-relaxed ${openTab === 5 ? 'block' : 'hidden'}`}>
          <FormAgentTraining />
        </div>
      </div>

      {/* Botões de navegação */}
      <div className="flex items-center mt-4">
        {/* Botão Cancelar */}
        <Link
          to="/"
          className="mr-4 inline-flex items-center justify-center rounded-md bg-red py-4 px-8 text-center font-medium text-white hover:bg-opacity-90"
        >
          Cancelar
        </Link>

        {/* Botão Anterior */}
        {openTab > 1 && (
          <button
            onClick={previousTab}
            className="mr-4 inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-8 text-center font-medium text-white hover:bg-opacity-90"
          >
            Anterior
          </button>
        )}

        {/* Botão Próximo ou Finalizar */}
        {openTab === 5 ? (
          <Link
            to="/"
            className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-8 text-center font-medium text-white hover:bg-opacity-90"
          >
            Finalizar
          </Link>
        ) : (
          <button
            onClick={nextTab}
            className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-8 text-center font-medium text-white hover:bg-opacity-90"
          >
            Próximo
          </button>
        )}
      </div>
    </div>
  );
};

export default TabAgentProfile;
