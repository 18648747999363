import { useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ColumnFilter from './ColumnFilter';
import DropdownFour from '../Dropdowns/DropdownFour';

const CampaingTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Campanha',
        accessor: 'campanha',
      },
      {
        Header: 'Canais',
        accessor: 'canais',
      },
      {
        Header: 'Investimento',
        accessor: 'investimento',
      },
      {
        Header: 'Conversões',
        accessor: 'conversões',
      },
      {
        Header: 'CPL',
        accessor: 'cpl',
      },
      {
        Header: 'ROAS',
        accessor: 'roas',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        // Adicionando uma nova coluna para o botão DropdownFour
        Header: 'Edição',
        Cell: ({ row }) => (
          <DropdownFour/> // Passe os dados da linha para o DropdownFour
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      { campanha: 'Promoção de Verão 2024', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      { campanha: 'John Doe', canais: 'ativo', investimento:'1', conversões: '1', cpl:"1", roas:'1', status:'1' },
      // Adicione mais dados conforme necessário
    ],
    []
  );

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 8 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    gotoPage,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <section className="data-table-common rounded-sm border border-stroke bg-white py-4 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex justify-between  px-8 pb-4">
        <div className="w-100">
          <input
            type="text"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-full rounded-md border border-stroke bg-transparent px-5 py-2.5 outline-none focus:border-virtuspurple dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
            placeholder="Search..."
          />
        </div>

        <div className="flex items-center font-medium">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="bg-transparent pl-2"
          >
            {[5, 8, 10, 20, 50].map((page) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </select>
          <p className="pl-2 text-black dark:text-white">Entries Per Page</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="datatable-table datatable-one w-full table-auto border-collapse break-words px-4 md:table-fixed"
        >
          <thead className="border-separate px-4">
            {headerGroups.map((headerGroup, key) => (
              <tr
                className="border-t border-stroke dark:border-strokedark"
                {...headerGroup.getHeaderGroupProps()}
                key={key}
              >
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={key}
                  >
                    <div className="flex items-center">
                      <span> {column.render('Header')}</span>
                    </div>
                    {column.canFilter ? column.render('Filter') : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <tr
                  className="border-t border-stroke dark:border-strokedark"
                  {...row.getRowProps()}
                  key={key}
                >
                  {row.cells.map((cell, key) => {
                    return (
                      <td {...cell.getCellProps()} key={key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between border-t border-stroke px-6 pt-5 dark:border-strokedark">
        <div className="flex">
          <button
            className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-virtuspurple hover:text-white"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1777 16.1156C12.009 16.1156 11.8402 16.0593 11.7277 15.9187L5.37148 9.44995C5.11836 9.19683 5.11836 8.80308 5.37148 8.54995L11.7277 2.0812C11.9809 1.82808 12.3746 1.82808 12.6277 2.0812C12.8809 2.33433 12.8809 2.72808 12.6277 2.9812L6.72148 8.99995L12.6559 15.0187C12.909 15.2718 12.909 15.6656 12.6559 15.9187C12.4871 16.0312 12.3465 16.1156 12.1777 16.1156Z"
                fill=""
              />
            </svg>
          </button>

          {pageOptions.map((_page, index) => (
            <button
              key={index}
              onClick={() => {
                gotoPage(index);
              }}
              className={`${
                pageIndex === index && 'bg-virtuspurple text-white'
              } mx-1 flex cursor-pointer items-center justify-center rounded-md p-1 px-3 hover:bg-virtuspurple hover:text-white`}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="flex cursor-pointer items-center justify-center rounded-md p-1 px-2 hover:bg-virtuspurple hover:text-white"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.82148 16.1156C5.65273 16.1156 5.51211 16.0593 5.37148 15.9468C5.11836 15.6937 5.11836 15.2999 5.37148 15.0468L11.2777 8.99995L5.37148 2.95308C5.11836 2.69995 5.11836 2.3062 5.37148 2.05308C5.62461 1.79995 6.01836 1.79995 6.27148 2.05308L12.6277 8.52183C12.8809 8.77495 12.8809 9.1687 12.6277 9.42183L6.27148 15.8906C6.15898 16.0312 5.99023 16.1156 5.82148 16.1156Z"
                fill=""
              />
            </svg>
          </button>
        </div>

        <div className="pt-2 text-sm">
          <span className="text-black dark:text-white">
            Page <strong>{pageIndex + 1}</strong> of {pageOptions.length}
          </span>
        </div>
      </div>
    </section>
  );
};

export default CampaingTable;
