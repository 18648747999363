import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import SelectGroupOne from '../../components/Forms/SelectGroup/SelectGroupOne';
import DefaultLayout from '../../layout/DefaultLayout';
import ListCapacidades from '../../components/List/ListCapacidades';
import ListFerramentas from '../../components/List/ListFerramentas';

const FormLayoutAgentModel = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Modelo" />

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        <div className="flex flex-col gap-9">
          {/* <!-- Contact Form --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Perfil do Agente
              </h3>
            </div>
            <div className=" py-4 px-6.5 dark:border-strokedark">
            <p>Configure um agente para conversar com sua base de contatos e realizar ações como prospectar qualificar, prestar ajuda e muito mais.</p>
            </div>
            <form action="#">
              <div className="p-6.5">
              <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Ferramentas
                  </label>
                  <ListFerramentas/>
                </div>
                <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                  <div className="w-full xl:w-1/1">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Capacidades
                    </label>
                    <ListCapacidades/>
                  </div>
                </div>

                <Link
                to="/"
                className="inline-flex items-center justify-center rounded-md bg-red py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 mr-4"
                >
                    Cancelar
                </Link>

                <Link
                to="/dashboard/Criação de Agente"
                className="inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                    Utilizar
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default FormLayoutAgentModel;
