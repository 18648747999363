import React, { useState, useEffect, useRef } from 'react';
import SelectGroupProfile from '../Forms/SelectGroup/SelectGroupProfile';
import SelectGroupAction from '../Forms/SelectGroup/SelectGroupAction';
import SelectGroupEmail from '../Forms/SelectGroup/SelectGroupEmail';
import SelectGroupGatilhos from '../Forms/SelectGroup/SelectGroupGatilhos';

const ModalAddAutomation: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const trigger = useRef<any>(null);
  const modal = useRef<any>(null);

  // Fechar ao pressionar a tecla 'Esc'
  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (!modalOpen || key !== 'Escape') return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [modalOpen]);

  // Prevenir o comportamento padrão do formulário
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Evita recarregar a página
    // Lógica adicional de envio de dados, se necessário
    setModalOpen(false); // Fecha o modal
  };

  return (
    <div>
      {/* Botão para abrir o modal */}
      <button
        ref={trigger}
        type="button" // Certifique-se de que o botão não é tratado como submit
        onClick={() => setModalOpen(!modalOpen)}
        className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
      >
        Nova automação
      </button>

      {/* Modal */}
      <div
        className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
          modalOpen ? 'block' : 'hidden'
        }`}
      >
        <div
          ref={modal}
          className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15"
        >
          <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            Nova Automação
          </h3>

          {/* Formulário */}
          <form onSubmit={handleSubmit}>
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Nome
                </label>
                <input
                  type="email"
                  placeholder="Insira"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>

              <SelectGroupGatilhos />
              <SelectGroupAction />
              <SelectGroupEmail />
            </div>

            {/* Botões */}
            <div className="-mx-3 flex flex-wrap gap-y-4">
              <div className="2xsm:w-1/2 w-full px-3">
                <button
                  type="button" // Previne comportamento submit
                  onClick={() => setModalOpen(false)}
                  className="inline-flex items-center justify-center rounded-md bg-meta-1 py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Cancelar
                </button>
              </div>
              <div className="2xsm:w-1/2 w-full px-3">
                <button
                  type="submit" // Ainda envia os dados, mas com prevenção do recarregamento
                  className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalAddAutomation;
