import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenByCredentials, sendAuthorization } from '../../api/auth';
import { User } from '../../types/user';

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (credentials: {
    email: string;
    password: string;
  }) => Promise<void>;
  loginWithGoogle: (token: string) => Promise<void>;
  logout: () => void;
  authorization: (url: string, options: object) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate(); // Hook para navegação

  // Função de login tradicional (email/senha)
  const login = async (credentials: {
    email: string;
    password: string;
  }) => {
    const data = await getTokenByCredentials(credentials);
    setUser(data.user);
  };

  // Função de login com Google (usando o token obtido do OAuth)
  const loginWithGoogle = async (token: string) => {
    try {
      // Envia o token para o backend para validação e autenticação
      const response = await sendAuthorization('/api/auth/token/', {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Verifica se a resposta é bem-sucedida
      if (response && response.user) {
        setUser(response.user);
      } else {
        throw new Error('Failed to authenticate with Google');
      }
    } catch (error) {
      console.error('Google login failed:', error);
      throw new Error('Google login failed');
    }
  };

  // Função de logout
  const logout = async () => {
    try {
      // Realiza o logout no backend
      await sendAuthorization('/api/auths/logout/', { method: 'POST' });

      // Limpa o localStorage
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('user');
      setUser(null);

      // Redireciona o usuário para a página de login após o logout
      navigate('/login'); // Alterar a rota conforme necessário
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const value: AuthContextType = {
    user,
    login,
    loginWithGoogle,
    logout,
    authorization: sendAuthorization,
    isAuthenticated: user ? true : false,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
