import React from 'react';
import TecnisaSelectEnterpriseGroupGoogle from '../SelectGroup/TecnisaSelectEnterpriseGroupGoogle';
import TecnisaSelectCampaignGroupGoogle from '../SelectGroup/TecnisaSelectCampaignGroupGoogle';

const TecnisaHeaderGoogle: React.FC = () => {
  return (
    <div className="col-span-12">
      <div className="rounded-sm border border-stroke bg-white py-3 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex justify-between gap-2.5 py-3 px-6 hover:bg-gray-2 dark:hover:bg-meta-4 sm:items-center sm:justify-start">
          <div className="flex items-center gap-5.5 sm:w-5/12 xl:w-3/12">
            <div className="hidden h-14 w-full max-w-14 items-center justify-center rounded-full border border-stroke bg-gray text-black-2 dark:border-strokedark dark:bg-graydark dark:text-white sm:flex">
              <img src="/src/images/brand/tecnisa.png" alt='Brand'></img>
            </div>

            <p className="font-medium text-black dark:text-white">
              Google
            </p>
          </div>

          <div className="hidden sm:block sm:w-1/12 xl:w-2/12">
            <p className="font-medium text-black dark:text-white">
            </p>
          </div>

          <div className="text-right sm:w-3/12 xl:w-6/12">
            <TecnisaSelectEnterpriseGroupGoogle />
          </div>

          <div className="text-right sm:w-3/12 xl:w-6/12">
            <TecnisaSelectCampaignGroupGoogle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TecnisaHeaderGoogle;
