import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import SelectGroupOne from '../../components/Forms/SelectGroup/SelectGroupOne';
import DefaultLayout from '../../layout/DefaultLayout';
import TabAgentProfile from '../../components/Tabs/TabAgentProfile';

const AgentCreation = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Agente" />

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        <div className="flex flex-col gap-9">
            <TabAgentProfile/>          
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AgentCreation;
