import { Link } from 'react-router-dom';
import SelectGroupSetor from '../../components/Forms/SelectGroup/SelectGroupSetor';
import SelectGroupTomDeVoz from '../../components/Forms/SelectGroup/SelectGroupTomDeVoz';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';

const FormLayoutAccont = () => {
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}

        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Configure as informações da sua empresa para serem usadas nas
          campanhas e demais materiais.
        </h3>

        {/* <div className="relative z-5 -mt-10 h-30 w-full max-w-30 rounded-full bg-white/20 p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
                <div className="relative drop-shadow-2">
                    <img src={userOne} alt="profile" />
                    <label
                        htmlFor="profile"
                        className="absolute bottom-0 right-0 flex h-8.5 w-8.5 cursor-pointer items-center justify-center rounded-full bg-primary text-white hover:bg-opacity-90 sm:bottom-2 sm:right-2"
                    >
                        <svg
                            className="fill-current"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.76464 1.42638C4.87283 1.2641 5.05496 1.16663 5.25 1.16663H8.75C8.94504 1.16663 9.12717 1.2641 9.23536 1.42638L10.2289 2.91663H12.25C12.7141 2.91663 13.1592 3.101 13.4874 3.42919C13.8156 3.75738 14 4.2025 14 4.66663V11.0833C14 11.5474 13.8156 11.9925 13.4874 12.3207C13.1592 12.6489 12.7141 12.8333 12.25 12.8333H1.75C1.28587 12.8333 0.840752 12.6489 0.512563 12.3207C0.184375 11.9925 0 11.5474 0 11.0833V4.66663C0 4.2025 0.184374 3.75738 0.512563 3.42919C0.840752 3.101 1.28587 2.91663 1.75 2.91663H3.77114L4.76464 1.42638ZM5.56219 2.33329L4.5687 3.82353C4.46051 3.98582 4.27837 4.08329 4.08333 4.08329H1.75C1.59529 4.08329 1.44692 4.14475 1.33752 4.25415C1.22812 4.36354 1.16667 4.51192 1.16667 4.66663V11.0833C1.16667 11.238 1.22812 11.3864 1.33752 11.4958C1.44692 11.6052 1.59529 11.6666 1.75 11.6666H12.25C12.4047 11.6666 12.5531 11.6052 12.6625 11.4958C12.7719 11.3864 12.8333 11.238 12.8333 11.0833V4.66663C12.8333 4.51192 12.7719 4.36354 12.6625 4.25415C12.5531 4.14475 12.4047 4.08329 12.25 4.08329H9.91667C9.72163 4.08329 9.53949 3.98582 9.4313 3.82353L8.43781 2.33329H5.56219Z"
                                fill=""
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.00004 5.83329C6.03354 5.83329 5.25004 6.61679 5.25004 7.58329C5.25004 8.54979 6.03354 9.33329 7.00004 9.33329C7.96654 9.33329 8.75004 8.54979 8.75004 7.58329C8.75004 6.61679 7.96654 5.83329 7.00004 5.83329ZM4.08337 7.58329C4.08337 5.97246 5.38921 4.66663 7.00004 4.66663C8.61087 4.66663 9.91671 5.97246 9.91671 7.58329C9.91671 9.19412 8.61087 10.5 7.00004 10.5C5.38921 10.5 4.08337 9.19412 4.08337 7.58329Z"
                                fill=""
                            />
                        </svg>
                        <input
                            type="file"
                            name="profile"
                            id="profile"
                            className="sr-only"
                        />
                    </label>
                </div>
			</div> */}

        <div>
          <label className="mb-3 block text-black dark:text-white">
            Logotipos
          </label>
          <input
            type="file"
            className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-roxo_claro file:px-5 file:py-3 file:text-virtuspurple file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
          />
        </div>

        <form action="#">
          <div className="p-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <label className="mb-2.5 block text-azul_medio dark:text-white">
                  Nome da empresa
                </label>
                <input
                  type="text"
                  placeholder="Insira"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="w-full xl:w-1/2">
              <label className="mb-2.5 block text-azul_medio dark:text-white">
                Site da empresa
              </label>
              <input
                type="text"
                placeholder="Insira"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="w-full xl:w-1/2">
              <SelectGroupSetor />
            </div>

            <div>
              <label className="mb-3 block text-black dark:text-white">
                Sobre a empresa
              </label>
              <textarea
                rows={6}
                placeholder="Insira"
                className="h-32 w-full resize-none rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary "
              ></textarea>
              <div className="w-full xl:w-1/2">
                <SelectGroupTomDeVoz />
              </div>
              <h3 className="font-renner text-sm text-azul_medio dark:text-white">
                Arquivos
              </h3>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <h3 className="font-renner text-sm text-grafite dark:text-white">
                  Faça o Upload documentos e imagens que ajudam a descrever os
                  podutos ou serviços. Nossos VirtusAgemts irão estudar o
                  material para aprender mais sobe o negócio, e utilizar essas
                  informações na produção dos materiais customizados, na
                  comunicação com clientes, e mais.
                </h3>
              </div>
              <div>
                <label className="mb-3 block text-black dark:text-white">
                  Tipos de arquivos aceitos: PDF, XLS, CSV, DOC, JPG e PNG.
                </label>
                <input
                  type="file"
                  className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-roxo_claro file:px-5 file:py-3 file:text-virtuspurple file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
                />
              </div>
            </div>
          </div>
          <div className="mb-7.5 flex flex-wrap gap-5 xl:gap-20">
            <Link
              to="#"
              className="inline-flex items-center justify-center rounded-md bg-virtuspurple px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
            >
              Salvar
            </Link>
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutAccont;
