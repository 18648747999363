import React from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DataTableOne from '../../components/DataTables/DataTableOne';
import DataTableTwo from '../../components/DataTables/DataTableTwo';
import DefaultLayout from '../../layout/DefaultLayout';
import MmmTableOne from '../../components/MmmComponents/MmmTableOne';
import CampaingTable from '../../components/DataTables/CampaingTable';
import ModalOne from '../../components/Modals/ModalOne';
import ModalAddAutomation from '../../components/Modals/ModalAddAutomation';

const CampaingList: React.FC = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Automation" />

      <div className="flex flex-col gap-5 md:gap-7 2xl:gap-10">
        <CampaingTable />
      </div>
      <ModalAddAutomation/>

    </DefaultLayout>
  );
};

export default CampaingList;
