import React from 'react'

interface Stocks {
  name: string;
  share: number;
  projeção: number;
  projeção_02: number;
  mes_01: string;
  mes_02: string;
}

const stocksItems: Stocks[] = [
  {
    name: 'Pmx',
    share: 67.7,
    projeção: 1.736,
    projeção_02: 1.748,
    mes_01: 'Projeção',
    mes_02: 'Projeção Mai',

  },
  {
    name: 'Pesquisa',
    share: 28.0,
    projeção: + 740,
    projeção_02: + 745,
    mes_01: 'Projeção',
    mes_02: 'Projeção Mai',

  },
  {
    name: 'GD',
    share: 6.3,
    projeção: + 166,
    projeção_02:168,
    mes_01: 'Projeção',
    mes_02: 'Projeção Mai',
 
  },
  {
    name: 'Total',
    share: 100,
    projeção: + 2.642,
    projeção_02: 2.660    ,
    mes_01: 'Projeção',
    mes_02: 'Projeção Mai',
  },
];

const MmmTableThree: React.FC = () => {
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:p-7.5 xl:col-span-7">
      <div className="mb-10 flex flex-wrap items-center justify-between gap-2">
        <div>
          <h4 className="text-title-sm2 font-bold text-black dark:text-white">
            Projeção de Distribuição de Investimento
          </h4>
        </div>
      </div>

      <div className="flex flex-col gap-[25px]">
        {stocksItems.map((item, key) => (
          <div
            key={key}
            className="grid grid-cols-2 items-center gap-5 xsm:grid-cols-3 sm:grid-cols-8"
          >
            <div className="flex items-center gap-4.5 sm:col-span-3 2xl:col-span-2">
              <div className="sm:block">
                <h5 className="font-bold text-black dark:text-white">
                  {item.name}
                </h5>
                <p className="text-xs font-medium">Nome</p>
              </div>
            </div>

            <div className="sm:col-span-2 sm:block">
              <h5 className="font-bold text-black dark:text-white">
                {item.share}%
              </h5>
              <p className="text-xs font-medium">share</p>
            </div>

            <div className="hidden xsm:block sm:col-span-1 2xl:col-span-2">
              <p
                className={`mb-0.5 flex items-center gap-[5px] text-sm font-bold leading-6 ${
                  item.projeção >= 0 ? 'text-meta-3' : 'text-red'
                }`}
              >
                {/* {item.projeção >= 0 ? (
                  <svg
                    className="fill-current"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.48804 0.0989982L8.49108 6.099L0.484996 6.099L4.48804 0.0989982Z"
                      fill=""
                    />
                  </svg>
                ) : (
                  <svg
                    className="fill-current"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.48804 6.099L0.484999 0.0989983L8.49108 0.098999L4.48804 6.099Z"
                      fill=""
                    />
                  </svg>
                )} */}
                {item.projeção}
              </p>
              <p className="text-xs">{item.mes_01}</p>
            </div>
            {/* <div className="hidden text-righ xsm:block sm:col-span-1 2xl:col-span-2">
              <p
                className={`mb-0.5 flex items-center gap-[5px] text-sm font-bold leading-6 ${
                  item.projeção_02 >= 0 ? 'text-meta-3' : 'text-red'
                }`}
              >
                {item.projeção_02 >= 0 ? (
                  <svg
                    className="fill-current"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.48804 0.0989982L8.49108 6.099L0.484996 6.099L4.48804 0.0989982Z"
                      fill=""
                    />
                  </svg>
                ) : (
                  <svg
                    className="fill-current"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.48804 6.099L0.484999 0.0989983L8.49108 0.098999L4.48804 6.099Z"
                      fill=""
                    />
                  </svg>
                )}
                R${item.projeção_02}
              </p>
              <p className="text-xs">{item.mes_02}</p>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MmmTableThree