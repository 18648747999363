import { useState } from 'react';
import SelectOptionThree from '../../components/Forms/SelectOption/SelectOptionTools';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';

const FormLayoutAgentTools = () => {
  const [openTab, setOpenTab] = useState(2);
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-medium text-black dark:text-white">
          Ferramentas do agente
        </h3>

        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Selecione ferramentas que o agente deve utilizar em suas ações.
        </h3>

        <SelectOptionThree />
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutAgentTools;
