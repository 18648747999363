import ProductOne from '@images/product/product-01.png';
import ProductTwo from '@images/product/product-02.png';
import ProductThree from '@images/product/product-03.png';
import ProductFour from '@images/product/product-04.png';
import { Product } from '../../types/product';
import DropdownFour from '../Dropdowns/DropdownFour';

const productData: Product[] = [
  {
    image: ProductOne,
    name: 'Apple Watch Series 7',
    category: 'Electronics',
    price: 296,
    sold: 22,
    profit: 45,
    status: 'Paid',
  },
  {
    image: ProductTwo,
    name: 'Macbook Pro M1',
    category: 'Electronics',
    price: 546,
    sold: 12,
    profit: 125,
    status: 'teste',
  },
  {
    image: ProductThree,
    name: 'Dell Inspiron 15',
    category: 'Electronics',
    price: 443,
    sold: 64,
    profit: 247,
    status: 'teste',
  },
  {
    image: ProductFour,
    name: 'HP Probook 450',
    category: 'Electronics',
    price: 499,
    sold: 72,
    profit: 103,
    status: 'Paid',
  },
];

const TableSeven = () => {
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="px-4 py-6 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          Campanhas
        </h4>
      </div>

      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-9 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-9 md:px-6 2xl:px-7.5">
              <div className="col-span-2 flex items-center">
                <p className="font-medium">Nome da Campanha</p>
              </div>
              <div className="col-span-2 items-center sm:flex">
                <p className="font-medium">Tipo de Campanha</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Investimento</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Conversões</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">ROAS</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Editar</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Status</p>
              </div>
            </div>

            {productData.map((product, key) => (
              <div
                className="grid grid-cols-9 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-9 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-2 flex items-center">
                  <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div className="h-12.5 w-15 rounded-md">
                      <img src={product.image} alt="Product" />
                    </div>
                    <p className="text-sm text-black dark:text-white">
                      {product.name}
                    </p>
                  </div>
                </div>
                <div className="col-span-2 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.category}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="text-sm text-black dark:text-white">
                    ${product.price}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="text-sm text-black dark:text-white">
                    {product.sold}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="text-sm text-meta-3">${product.profit}</p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    <DropdownFour />
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  {/* <p className="text-sm text-meta-3">${product.status}</p> */}
                  <p
                    className={`inline-flex rounded-full bg-opacity-10 px-3 py-1 text-sm font-medium ${
                      product.status === 'Paid'
                        ? 'bg-success text-success'
                        : product.status === 'Unpaid'
                        ? 'bg-danger text-danger'
                        : 'bg-warning text-warning'
                    }`}
                  >
                    {product.status}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSeven;
