import { TeamList } from '../../types/TeamList';
// import ProductOne from '@images/product/product-01.png';
// import ProductTwo from '@images/product/product-02.png';
// import ProductThree from '@images/product/product-03.png';
// import ProductFour from '@images/product/product-04.png';
import DropdownFour from '../Dropdowns/DropdownFour';

const productData: TeamList[] = [
  {
    team: 'Gestores',
  },
  {
    team: 'Comercial',
  },
  {
    team: 'Marketing',
  },
];

const TableTeam = () => {
  // Função para prevenir o comportamento padrão
  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault(); // Previne qualquer comportamento padrão como submit ou recarregamento
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto ">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
              <div className="col-span-1 items-center sm:flex">
                <p className="font-medium">Equipe</p>
              </div>

              <div className="col-span-1 flex items-center">
                <p className="font-medium">Edição</p>
              </div>
            </div>

            {productData.map((product, key) => (
              <div
                className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.team}
                  </p>
                </div>

                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    {/* Wrapper com onClick que previne comportamento padrão */}
                    <div onClick={handleButtonClick}>
                      <DropdownFour />
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTeam;
