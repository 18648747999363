import React, { useState } from 'react';
import FormLayoutSecurity from '../../pages/Form/FormLayoutSecurity';
import AccordionItemFour from './AccordionItemFour';
import TableNotificationOne from '../Tables/TableNotificationOne';
import TableNotificationTwo from '../Tables/TableNotificationTwo';
// import { FAQ } from '../types/faq';
import { FAQ } from '../types/faq'

const text = <TableNotificationTwo/>

const faqs: FAQ[] = [
  {
    id: 1,
    header: `Campanha`,
    text
  },
];

const AccordionFour: React.FC = () => {
  const [active, setActive] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <div className="">
      {/* <div className="border-b border-stroke px-4 py-4 dark:border-strokedark sm:px-6 xl:px-7.5">
        <h3 className="font-medium text-black dark:text-white">
          Accordions Style 2
        </h3>
      </div> */}

      <div className="p-4 sm:p-6 xl:p-12.5">
        <div className="flex flex-col gap-7.5">
          {faqs.map((faq) => {
            return (
              <AccordionItemFour
                key={faq.id}
                active={active}
                handleToggle={handleToggle}
                faq={faq}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccordionFour;
