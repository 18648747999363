import React, { useState } from 'react';
import { QuestionsList } from '../../types/QuestionsList';
import DropdownFour from '../Dropdowns/DropdownFour';
import PaginationThree from '../Paginations/paginationKn';

const productData: QuestionsList[] = [
  { pergunta: 'Pergunta 1', resposta: 'resposta 1' },
  { pergunta: 'Pergunta 1', resposta: 'resposta 2' },
  { pergunta: 'Pergunta 3', resposta: 'resposta 3' },
  { pergunta: 'Pergunta 4', resposta: 'resposta 4' },
  { pergunta: 'Pergunta 5', resposta: 'resposta 5' },
  { pergunta: 'Pergunta 6', resposta: 'resposta 6' },
  { pergunta: 'Pergunta 7', resposta: 'resposta 7' },
  { pergunta: 'Pergunta 8', resposta: 'resposta 8' },
  { pergunta: 'Pergunta 9', resposta: 'resposta 9' },
  { pergunta: 'Pergunta 10', resposta: 'resposta 10' },
  { pergunta: 'Pergunta 11', resposta: 'resposta 11' },
  { pergunta: 'Pergunta 12', resposta: 'resposta 12' },
  { pergunta: 'Pergunta 13', resposta: 'resposta 13' },
  { pergunta: 'Pergunta 14', resposta: 'resposta 14' },
  { pergunta: 'Pergunta 15', resposta: 'resposta 15' },
  { pergunta: 'Pergunta 16', resposta: 'resposta 16' },
  
];

const DataTableQuestions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  // Lógica para selecionar itens da página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto ">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-3 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-3 md:px-6 2xl:px-7.5">
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Pergunta</p>
              </div>
              <div className="col-span-1 items-center sm:flex">
                <p className="font-medium">Resposta</p>
              </div>
              <div className="col-span-1 flex items-center">
                <p className="font-medium">Edição</p>
              </div>
            </div>

            {currentItems.map((product, key) => (
              <div
                className="grid grid-cols-3 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-3 md:px-6 2xl:px-7.5"
                key={key}
              >
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.pergunta}
                  </p>
                </div>
                <div className="col-span-1 items-center sm:flex">
                  <p className="text-sm text-black dark:text-white">
                    {product.resposta}
                  </p>
                </div>
                <div className="col-span-1 flex items-center">
                  <p className="font-medium">
                    <div onClick={handleButtonClick}>
                      <DropdownFour />
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Componente de Paginação */}
      <PaginationThree
        itemsPerPage={itemsPerPage}
        totalItems={productData.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default DataTableQuestions;
