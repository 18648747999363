import { Link } from 'react-router-dom';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';

const FormLayoutSecurity = () => {
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Redefina sua senha de acesso e comfigure a dupla autenticação.
        </h3>

        <form action="#">
          <div className="p-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <h3 className="font-renner text-sm text-azul_medio dark:text-white">
                  Redefinir senha
                </h3>
                <input
                  type="text"
                  placeholder="Senha atual"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <input
                  type="text"
                  placeholder="Nova senha"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="mb-7.5 flex flex-wrap gap-5 xl:gap-20">
              <Link
                to="#"
                className="inline-flex items-center justify-center rounded-md bg-virtuspurple px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                Salvar
              </Link>
            </div>

            <h3 className="font-renner text-sm text-azul_medio dark:text-white">
              Autenticação de dois fatores (2FA)
            </h3>

            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <h3 className="font-renner text-sm text-grafite dark:text-white">
                A autenticação de dois fatoes é uma medida de segurança
                melhorada. Uma vez ativada, voce será exigido a fornecer dois
                tipos de identificação ao etrar na Virtus. Aplicativos de
                segurança, como Google Authenticator e SMS, são compatíveis.
              </h3>
            </div>

            <div className="mb-7.5 flex flex-wrap gap-5 xl:gap-20">
              <Link
                to="#"
                className="inline-flex items-center justify-center rounded-md bg-virtuspurple px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                Configurar 2FA
              </Link>
            </div>
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutSecurity;
