import React from 'react';

const TecnisaFunnel: React.FC = () => {
  return (
    <div className="col-span-12 overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
      <div className="space-y-2.5 border-t border-stroke p-6 dark:border-strokedark md:p-6 xl:p-7.5">
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-medium text-black dark:text-white">Impressões</p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[100%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              16,38 Mi
            </div>
          </div>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryTwo} alt="canada" /> */}
            <p className="font-medium text-black dark:text-white">Cliques</p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[80%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              298,21 Mil
            </div>
          </div>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryThree} alt="france" /> */}
            <p className="font-medium text-black dark:text-white">Leads</p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[60%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              4960
            </div>
          </div>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryFour} alt="italy" /> */}
            <p className="font-medium text-black dark:text-white">
              Visitas Realizadas
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[40%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              Null
            </div>
          </div>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryFive} alt="australia" /> */}
            <p className="font-medium text-black dark:text-white">Contratos</p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[20%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              Null
            </div>
          </div>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countrySix} alt="india" /> */}
            <p className="font-medium text-black dark:text-white">Vendas</p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[10%] items-center justify-center rounded bg-primary text-xs font-medium text-white">
              Null
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TecnisaFunnel;
