import { BRANDTWO } from '../../types/brandTwo';
// import BrandOne from '@images/brand/brand-01.svg';
// import BrandTwo from '@images/brand/brand-02.svg';
// import BrandThree from '@images/brand/brand-03.svg';
// import BrandFour from '@images/brand/brand-04.svg';
// import BrandFive from '@images/brand/brand-05.svg';

const brandData: BRANDTWO[] = [
  {
    situacao: 'Cancelado',
    google: 2450,
    facebook: 5862,
    total: 8312,
    google_percentage: 29,
    facebook_percentage: 71,
  },
  {
    situacao: 'Contato',
    google: 1533,
    facebook: 5214,
    total: 6747,
    google_percentage: 23,
    facebook_percentage: 77,
  },
  {
    situacao: 'Lead Aceito',
    google: 482,
    facebook: 2357,
    total: 2839,
    google_percentage: 17,
    facebook_percentage: 83,
  },
  {
    situacao: 'Não Perturbe',
    google: 169,
    facebook: 536,
    total: 705,
    google_percentage: 24,
    facebook_percentage: 76,
  },
  {
    situacao: 'Escritura',
    google: 46,
    facebook: 63,
    total: 109,
    google_percentage: 42,
    facebook_percentage: 58,
  },
  {
    situacao: 'Visita realizada',
    google: 40,
    facebook: 74,
    total: 114,
    google_percentage: 35,
    facebook_percentage: 65,
  },
  {
    situacao: 'Visita agendada',
    google: 8,
    facebook: 18,
    total: 26,
    google_percentage: 31,
    facebook_percentage: 69,
  },
  {
    situacao: 'Contrato',
    google: 8,
    facebook: 9,
    total: 17,
    google_percentage: 47,
    facebook_percentage: 53,
  },
  {
    situacao: 'Visita Cancelada',
    google: 6,
    facebook: 15,
    total: 21,
    google_percentage: 29,
    facebook_percentage: 71,
  },
  {
    situacao: 'Proposta',
    google: 3,
    facebook: 2,
    total: 5,
    google_percentage: 60,
    facebook_percentage: 40,
  },
  {
    situacao: 'Mql',
    google: 2,
    facebook: 4,
    total: 6,
    google_percentage: 33,
    facebook_percentage: 67,
  },
  {
    situacao: 'Oferta Ativa',
    google: 0,
    facebook: 0,
    total: 0,
    google_percentage: 0,
    facebook_percentage: 0,
  },
  {
    situacao: 'ISA',
    google: 1,
    facebook: 0,
    total: 1,
    google_percentage: 100,
    facebook_percentage: 0,
  },
];

const MmmTableSix = () => {
  return (
    <div className="overflow-hidden rounded-[10px]">
      <div className="max-w-full overflow-x-auto">
        <div className="min-w-[1170px]">
          <div className="rounded-sm border border-stroke bg-white px-5 pb-2.5 pt-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
              CRM Tecnisa
            </h4>
            <div className="flex flex-col">
              <div className="grid grid-cols-6 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-6">
                <div className="p-2.5 xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Situação
                  </h5>
                </div>
                <div className="p-2.5 text-center xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Google
                  </h5>
                </div>
                <div className="p-2.5 text-center xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Facebook
                  </h5>
                </div>
                <div className="p-2.5 text-center sm:block xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Total
                  </h5>
                </div>
                <div className="p-2.5 text-center sm:block xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Google %
                  </h5>
                </div>
                <div className="p-2.5 text-center sm:block xl:p-5">
                  <h5 className="text-sm font-medium uppercase xsm:text-base">
                    Facebook %
                  </h5>
                </div>
              </div>

              {brandData.map((brand, key) => (
                <div
                  className={`grid grid-cols-6 sm:grid-cols-6 ${
                    key === brandData.length - 1
                      ? ''
                      : 'border-b border-stroke dark:border-strokedark'
                  }`}
                  key={key}
                >
                  <div className="flex items-center gap-3 p-2.5 xl:p-5">
                    {/* <div className="flex-shrink-0">
                                        <img src={brand.logo} alt="Brand" />
                                    </div> */}
                    <p className="text-black dark:text-white sm:block">
                      {brand.situacao}
                    </p>
                  </div>

                  <div className="flex items-center justify-center p-2.5 xl:p-5">
                    <p className="text-meta-3">{brand.google}</p>
                  </div>

                  <div className="flex items-center justify-center p-2.5 xl:p-5">
                    <p className="text-meta-3">{brand.facebook}</p>
                  </div>

                  <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
                    <p className="text-black dark:text-white">{brand.total}</p>
                  </div>

                  <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
                    <p className="text-meta-5">{brand.google_percentage}%</p>
                  </div>

                  <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
                    <p className="text-meta-5">{brand.facebook_percentage}%</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MmmTableSix;
