import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartFourState {
  series: { data: number[] }[];
}

const ChartAverageCPLByDateFacebook: React.FC = () => {
  const [state, setState] = useState<ChartFourState>({
    series: [
      {
        data: [
          168, 385, 201, 298, 187, 195, 291, 110, 215, 390, 280, 112, 123, 212,
          270, 190, 310, 115, 90, 380, 112, 223, 292, 170, 290, 110, 115, 290,
          380, 312, 168, 385, 201, 298, 187
        ], 
      },
    ],
  });

  // Update the state
  const updateState = () => {
    setState((prevState) => ({
      ...prevState,
      // Update the desired properties
    }));
  };
  updateState;

  const options: ApexOptions = {
    colors: ['#3C50E0'],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        // endingShape: "rounded",
        borderRadius: 2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        '01/03/2024',
      '02/03/2024',
      '03/03/2024',
      '04/03/2024',
      '05/03/2024',
      '06/03/2024',
      '07/03/2024',
      '08/03/2024',
      '09/03/2024',
      '10/03/2024',
      '11/03/2024',
      '12/03/2024',
      '13/03/2024',
      '14/03/2024',
      '15/03/2024',
      '16/03/2024',
      '17/03/2024',
      '18/03/2024',
      '19/03/2024',
      '20/03/2024',
      '21/03/2024',
      '22/03/2024',
      '23/03/2024',
      '24/03/2024',
      '25/03/2024',
      '26/03/2024',
      '27/03/2024',
      '28/03/2024',
      '29/03/2024',
      '30/03/2024',
      '01/04/2024',
      '02/04/2024',
      '03/04/2024',
      '04/04/2024',
      '05/04/2024',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Satoshi',

      markers: {
        radius: 99,
      },
    },
    // yaxis: {
    //   title: false,
    // },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
      <div>
        <h3 className="text-xl font-semibold text-black dark:text-white">
          CPL Médio Por Data
        </h3>
      </div>

      <div className="mb-2">
        <div id="chartFour" className="-ml-5">
          <ReactApexChart
            options={options}
            series={state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartAverageCPLByDateFacebook;
