import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// import DropdownDefault from '../../components/Dropdowns/DropdownDefault';
// import SettingsList from '../../components/settings/settingsList';
import SettingsMenuList from '../../components/settings/settingsMenuList';
import SettingsMenuProfile from '../../components/settings/settingsMenuProfile';
import TabSettingUsersAndTeams from '../../components/Tabs/TabSettingUsersAndTeams';
import FormUsersAndTeams from '../Form/FormUsersAndTeams';
// import InboxList from '../../components/Inbox/InboxList';
// import InboxMenuList from '../../components/Inbox/InboxMenuList';
import DefaultLayout from '../../layout/DefaultLayout';

const UsersAndTeams: React.FC = () => {
  const [inboxSidebarToggle, setInboxSidebarToggle] = useState(false);

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Configurações" />

      <div className="sm:h-[calc(100vh-174px)] h-[calc(100vh-186px)] overflow">
        <div className="h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark lg:flex">
          <div
            className={`lg:w-1/5 fixed bottom-0 top-22.5 z-999 flex w-[230px] -translate-x-[120%] flex-col rounded-md border border-stroke bg-white dark:border-strokedark dark:bg-boxdark lg:static lg:translate-x-0 lg:border-none ${
              inboxSidebarToggle && '!translate-x-0 duration-300 ease-linear'
            }`}
          >
            <button
              onClick={() => setInboxSidebarToggle(!inboxSidebarToggle)}
              className={`absolute -right-20 z-99999 block rounded-md border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden ${
                inboxSidebarToggle && '!-right-9'
              }`}
            >
              <svg
                className="h-5 w-5 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path d="M 22.1875 2.28125 L 20.78125 3.71875 L 25.0625 8 L 4 8 L 4 10 L 25.0625 10 L 20.78125 14.28125 L 22.1875 15.71875 L 28.90625 9 Z M 9.8125 16.28125 L 3.09375 23 L 9.8125 29.71875 L 11.21875 28.28125 L 6.9375 24 L 28 24 L 28 22 L 6.9375 22 L 11.21875 17.71875 Z" />
              </svg>
            </button>


            <div className="no-scrollbar max-h-full overflow-auto py-6">
              <SettingsMenuProfile />
            </div>
          </div>
          <div className="lg:w-4/5 flex h-full flex-col border-l border-stroke dark:border-strokedark">
            {/* <!-- ====== Inbox List Start --> */}

            <div className="h-full">
              <TabSettingUsersAndTeams />
            </div>
            {/* // <!-- ====== Inbox List End --> */}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default UsersAndTeams;
