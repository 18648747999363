import React from 'react';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// import ChartTen from '../../components/Charts/ChartTen';
// import DownloadList from '../../components/DownloadList';
import DmaHeader from '../../components/DmaComponents/DmaHeader';
import DmaFooter from '../../components/DmaComponents/DmaFooter';
import DmaDetailsList from '../../components/DmaComponents/DmaDetailsList';
import DmaQualityControl from '../../components/DmaComponents/DmaQualityControl';
// import StorageChart from '../../components/Storage/StorageChart';
import DmaChartCalls from '../../components/DmaComponents/DmaChartCalls';
import DmaCharAnsweredCalls from '../../components/DmaComponents/DmaChartAnsweredCalls';
import DmaUnansweredCallstatus from '../../components/DmaComponents/DmaUnansweredCallStatus';
import DmaAnsweredCallsStatus from '../../components/DmaComponents/DmaAnsweredCallsStatus';
import DmaDisinterestStatus from '../../components/DmaComponents/DmaDisinterestStatus';
import DmaChartVehicleFinancing from '../../components/DmaComponents/DmaChartVehicleFinancing';
// import StorageList from '../../components/Storage/StorageList';
import DmaGeneralMetrics from '../../components/DmaComponents/DmaGeneralMetrics';
import DmaConsultantContact from '../../components/DmaComponents/DmaConsultantContact';
import DmaCarsInterest from '../../components/DmaComponents/DmaCarsInterest';
import DmaChartInterest from '../../components/DmaComponents/DmaChatsInterest';
import DefaultLayout from '../../layout/DefaultLayout';
import DmaVehicleforExchange from '../../components/DmaComponents/DmaVehicleforExchange';
import DmaPurchaseForecast from '../../components/DmaComponents/DmaPurchaseForecast';

const DMA: React.FC = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="DMA" />

      <div className="col-span-12 xl:col-span-4 py-6">
        <DmaHeader />
      </div>
      <DmaDetailsList />
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-4">
            <div className="flex flex-col gap-4 sm:flex-row md:gap-6 xl:flex-col xl:gap-7.5">
                <DmaChartCalls />
                <DmaCharAnsweredCalls />
            </div>
        </div>
        <div className="col-span-12 xl:col-span-8">
          <DmaChartInterest />
        </div>
        <div className="col-span-12 xl:col-span-6">
          <DmaUnansweredCallstatus />
        </div>
        <div className="col-span-12 xl:col-span-6">
          <DmaAnsweredCallsStatus />
        </div>

        <div className="col-span-12 xl:col-span-5">
            <div className="flex flex-col gap-4 sm:flex-row md:gap-6 xl:flex-col xl:gap-7.5">
                <DmaDisinterestStatus />
                <DmaVehicleforExchange/>
            </div>
        </div>
        <div className="col-span-12 xl:col-span-7">
          <DmaChartVehicleFinancing />
        </div>

        <div className="col-span-12 xl:col-span-6">
          <DmaPurchaseForecast />
        </div>
        <div className="col-span-12 xl:col-span-6">
          <DmaCarsInterest />
        </div>
      </div>

      <div className="mt-7.5 grid grid-cols-1 gap-4 md:gap-6 2xl:gap-7.5">
        <h2 className="text-title-md2 font-bold text-black dark:text-white">
              Controle de Qualidade
        </h2>
      </div>
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-12">
          <DmaQualityControl />
        </div>
      </div>

      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-4">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
             Contato do consultor
          </h2>
          <DmaConsultantContact />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Compareceu ao evento
          </h2>
          <DmaConsultantContact />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Comprou na concessionária
          </h2>
          <DmaConsultantContact />
        </div>
      </div>

      <div className="mt-7.5 grid grid-cols-1 gap-4 md:gap-6 2xl:gap-7.5">
        <h2 className="text-title-md2 font-bold text-black dark:text-white">
          Métricas Gerais
        </h2>
      </div>
      <div className="mt-7.5 grid grid-cols-5 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-12">
          <DmaGeneralMetrics />
        </div>
      </div>

      <div className="mt-7.5 grid grid-cols-5 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-12">
          <DmaFooter />
        </div>
      </div>

      
    </DefaultLayout>
  );
};

export default DMA;
