import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import SelectGroupThree from '../../components/Forms/SelectGroup/SelectGroupThree';
import SelectOptionOne from '../../components/Forms/SelectOption/SelectOptionOne';
import SelectOptionThree from '../../components/Forms/SelectOption/SelectOptionThree';
import SelectOptionTwo from '../../components/Forms/SelectOption/SelectOptionTwo';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';
import TabAddProfile from '../../components/Tabs/TabAddProfile';
import { Link } from 'react-router-dom'

const FormLayoutModalProfile = () => {
  return (
    <DefaultLayoutTwo>
      <Breadcrumb pageName="Adicionar Perfil" />

      <div className="grid grid-cols-1 gap-20 sm:grid-cols-1">

        <div className="flex flex-col gap-9">
          {/* <!-- Survey Form --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
             
              <TabAddProfile/>

              

              
            </div>
          </div>
        </div>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutModalProfile;
