import { Origins } from '../../types/origins';
import TableSection from './TableSection/TableSection';

const productDataGoogle: Origins[] = [
  {
    origem: 'Campanha de lançamento Produto M...',
    leads: 327,
    oportunidades: 42,
    vendas: 4,
  },
  {
    origem: 'Campanha de Remarketing na rede...',
    leads: 283,
    oportunidades: 59,
    vendas: 9,
  },
  {
    origem: 'Campanha Palavra Chave de cauda...',
    leads: 94,
    oportunidades: 42,
    vendas: 1,
  },
];

const productDataOrganico: Origins[] = [
  {
    origem: 'Blog Post - 10 motivos para realizar u...',
    leads: 327,
    oportunidades: 42,
    vendas: 4,
  },
  {
    origem: 'Comarketing - Blog do Parceiro - Post...',
    leads: 283,
    oportunidades: 59,
    vendas: 9,
  },
];

const productDataFacebook: Origins[] = [
  {
    origem: 'Campanha de lançamento Produto M...',
    leads: 327,
    oportunidades: 42,
    vendas: 4,
  },
  {
    origem: 'Campanha de Remarketing na rede...',
    leads: 283,
    oportunidades: 59,
    vendas: 9,
  },
  {
    origem: 'Campanha Palavra Chave de cauda...',
    leads: 94,
    oportunidades: 42,
    vendas: 1,
  },
];
const productDataOffline: Origins[] = [
  {
    origem: 'Campanha de lançamento Produto M...',
    leads: 327,
    oportunidades: 42,
    vendas: 4,
  },
  {
    origem: 'Campanha de Remarketing na rede...',
    leads: 283,
    oportunidades: 59,
    vendas: 9,
  },
  {
    origem: 'Campanha Palavra Chave de cauda...',
    leads: 94,
    oportunidades: 42,
    vendas: 1,
  },
];

const TableMMM = () => {
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="px-4 py-6 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          Principais Origens
        </h4>
      </div>

      {/* Aplicando a altura máxima e o scroll vertical */}
      <div className="max-h-96 overflow-y-scroll">
        <TableSection title="Google" productData={productDataGoogle} />
        <TableSection title="Orgânico" productData={productDataOrganico} />
        <TableSection title="Facebook" productData={productDataFacebook} />
        <TableSection title="Offline" productData={productDataOffline} />
      </div>
    </div>
  );
};

export default TableMMM;
