import React from 'react';

import BrandSix from '@images/brand/greencaricon.png';
import BrandSeven from '@images/brand/redcaricon.png';

interface Stocks {
  image: string;
  name: string;
  qtd: number;
}

const stocksItems: Stocks[] = [
  {
    image: BrandSix,
    name: 'Sim',
    qtd: 171,
  },
  {
    image: BrandSeven,
    name: 'Não',
    qtd: 78,
  },
];

const DmaVehicleforExchange: React.FC = () => {
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5.5 py-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-5">
      <div className="mb-7.5 flex flex-wrap items-center justify-between gap-2 px-2">
        <div>
          <h4 className="text-title-sm2 font-bold text-black dark:text-white">
            Carro para Troca
          </h4>
        </div>
      </div>

      <div className="flex flex-col">
        {stocksItems.map((item, key) => (
          <div
            key={key}
            className="flex items-center justify-between rounded-[5px] px-4 py-3.5 hover:bg-[#F8FAFD] dark:hover:bg-meta-4"
          >
            <div className="flex items-center gap-3">
              <div className="h-10 w-10 rounded-full">
                <img src={item.image} alt="brand" />
              </div>

              <div>
                <h5 className="text-sm font-bold leading-6 text-black dark:text-white">
                  {item.name}
                </h5>
              </div>
            </div>

            <div className="text-right">
              <p className="mb-1 font-medium text-black dark:text-white">
                {item.qtd}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DmaVehicleforExchange;
