import React from 'react';

const DmaDetailsList: React.FC = () => {
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5">
        <div className="flex justify-between border-b border-r border-stroke px-7.5 py-7 dark:border-strokedark xl:border-b-0">
          <div className="flex items-center gap-5.5">
            <div>
              <p className="text-lg font-medium text-[#9B51E0]">Ligações a Realizar</p>
            </div>
          </div>

          <div>
            <p className="mt-1.5 font-medium text-black dark:text-white">
              5.000
            </p>
          </div>
        </div>

        <div className="flex justify-between border-b border-r border-stroke px-7.5 py-7 dark:border-strokedark xl:border-b-0">
          <div className="flex items-center gap-5.5">

            <div>
              <p className="text-lg font-medium text-[#219653]">Total de Ligações</p>
            </div>
          </div>

          <div>
            <p className="mt-1.5 font-medium text-black dark:text-white">
              5.000
            </p>
          </div>
        </div>

        <div className="flex justify-between border-b border-r border-stroke px-7.5 py-7 dark:border-strokedark sm:border-b-0">
          <div className="flex items-center gap-5.5">
            <div>
              <p className="text-lg font-medium text-[#2F80ED]">Ligações Pendentes</p>
            </div>
          </div>

          <div>
            <p className="mt-1.5 font-medium text-black dark:text-white">
              0
            </p>
          </div>
        </div>

        <div className="flex justify-between border-b border-r border-stroke px-7.5 py-7 dark:border-strokedark sm:border-b-0">
          <div className="flex items-center gap-5.5">
            <div>
              <p className="text-lg font-medium text-[#3BA2B8]">Interessados</p>
            </div>
          </div>

          <div>
            <p className="mt-1.5 font-medium text-black dark:text-white">
              57
            </p>
          </div>
        </div>

        <div className="flex justify-between px-7.5 py-7">
          <div className="flex items-center gap-5.5">
            <div>
              <p className="text-lg font-medium text-[#F2994A]">Agendamentos</p>
            </div>
          </div>

          <div>
            <p className="mt-1.5 font-medium text-black dark:text-white">
              192
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmaDetailsList;
