import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import DropdownDefault from '../Dropdowns/DropdownDefault';

interface ChartsRevenueProjectionState {
  series: {
    name: string;
    data: number[];
  }[];
}

const ChartsRevenueProjection: React.FC = () => {
  const [state, setState] = useState<ChartsRevenueProjectionState>({
    series: [
      {
        name: 'Projeção',
        data: [168, 285, 131, 248, 187, 295, 191, 269, 201, 185, 252, 151],
      },

      {
        name: 'Realizado',
        data: [268, 185, 251, 198, 287, 205, 281, 199, 259, 185, 150, 111],
      },
    ],
  });

  const options: ApexOptions = {
    legend: {
      show: false,
    },
    colors: ['#F4BE37', '#8057C6'], // Definindo as cores para Projeção e Realizado
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      height: 200,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 320,
          },
        },
      },
    ],
    stroke: {
      width: [2, 2],
      curve: 'smooth',
    },
    markers: {
      size: 0,
    },
    grid: {
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'category',
      categories: [
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '0px',
        },
      },
    },
  };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
      <div className="mb-6 flex items-start justify-between">
        <div>
          <h4 className="text-title-sm2 font-bold text-black dark:text-white">
            Projeção de Receitas
          </h4>
          <div className="mt-2.5 flex gap-2.5">
          </div>
        </div>
        {/* <DropdownDefault /> */}
      </div>

      {/* Legenda */}
      <div className="flex justify-start mb-4">
        <div className="flex items-center mr-4">
          <span
            className="w-4 h-4 rounded-full mr-2"
            style={{ backgroundColor: '#F4BE37' }} // Cor da série Projeção
          ></span>
          <span>Projeção</span>
        </div>
        <div className="flex items-left">
          <span
            className="w-4 h-4 rounded-full mr-2"
            style={{ backgroundColor: '#8057C6' }} // Cor da série Realizado
          ></span>
          <span>Realizado</span>
        </div>
      </div>

      {/* Gráfico */}
      <div>
        <div id="chartSix" className="-ml-5">
          <ReactApexChart
            options={options}
            series={state.series}
            type="area"
            height={150}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartsRevenueProjection;
