import { Origins } from "../../../types/origins";

interface TableSectionProps {
    title: string;
    productData: Origins[];
  }
  
  
  const TableSection = ({ title, productData }: TableSectionProps) => (
    <>
      <div className="py-3 px-2 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-azul_escuro dark:text-white">
          {title}
        </h4>
      </div>
      <div className="grid grid-cols-7 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
        <div className="col-span-3 flex items-center">
          <p className="font-medium">Origem</p>
        </div>
        <div className="col-span-1 flex items-center">
          <p className="font-medium">Leads</p>
        </div>
        <div className="col-span-2 flex items-center">
          <p className="font-medium">Oportunidades</p>
        </div>
        <div className="col-span-1 flex items-center">
          <p className="font-medium">Vendas</p>
        </div>
      </div>
  
      {productData.map((origins, key) => (
        <div
          className="grid grid-cols-7 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5"
          key={key}
        >
          <div className="col-span-3 flex items-center">
            <p className="text-sm text-black dark:text-white">
              {origins.origem}
            </p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">{origins.leads}</p>
          </div>
          <div className="col-span-2 flex items-center">
            <p className="text-sm text-black dark:text-white">
              ${origins.oportunidades}
            </p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">
              ${origins.Vendas}
            </p>
          </div>
        </div>
      ))}
    </>
  );
  
  export default TableSection