import React from 'react';

const Funnel: React.FC = () => {
  return (
    <div className="col-span-12 overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
      <div className="space-y-2.5 border-t border-stroke p-6 dark:border-strokedark md:p-6 xl:p-7.5">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Funil de resultados
          </h5>
        </div>
        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-semibold text-virtuspurple dark:text-white">
              Visitantes
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[100%] items-center justify-center rounded bg-virtuspurple text-xs font-medium text-white">
              6.416
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-xs text-cinzaClaro dark:text-white">
              % conversão
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="items-left justify-left absolute left-0 top-0 flex h-full w-[100%] rounded bg-white text-xs font-medium text-cinzaClaro">
              16%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryTwo} alt="canada" /> */}
            <p className="font-semibold text-virtuspurple dark:text-white">
              Leads
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[80%] items-center justify-center rounded bg-virtuspurple text-xs font-medium text-white">
              1.036
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-xs text-cinzaClaro dark:text-white">
              % conversão
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="items-left justify-left absolute left-0 top-0 flex h-full w-[100%] rounded bg-white text-xs font-medium text-cinzaClaro">
              20%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryThree} alt="france" /> */}
            <p className="font-semibold text-virtuspurple dark:text-white">
              Qualificados
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[60%] items-center justify-center rounded bg-virtuspurple text-xs font-medium text-white">
              203
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-xs text-cinzaClaro dark:text-white">
              % conversão
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="items-left justify-left absolute left-0 top-0 flex h-full w-[100%] rounded bg-white text-xs font-medium text-cinzaClaro">
              59%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryFour} alt="italy" /> */}
            <p className="font-semibold text-virtuspurple dark:text-white">
              Opotunidades
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[40%] items-center justify-center rounded bg-virtuspurple text-xs font-medium text-white">
              119
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryOne} alt="usa" /> */}
            <p className="font-xs text-cinzaClaro dark:text-white">
              % conversão
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="items-left justify-left absolute left-0 top-0 flex h-full w-[100%] rounded bg-white text-xs font-medium text-cinzaClaro">
              15%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            {/* <img src={countryFive} alt="australia" /> */}
            <p className="font-semibold text-virtuspurple dark:text-white">
              Vendas
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[20%] items-center justify-center rounded bg-virtuspurple text-xs font-medium text-white">
              18
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funnel;
