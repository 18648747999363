import React from 'react';
import TecnisaSelectEnterpriseGroupGoogle from '../SelectGroup/TecnisaSelectEnterpriseGroupGoogle';
import TecnisaSelectCampaignGroupFacebook from '../SelectGroup/TecnisaSelectCampaignGroupFacebook';
import TecnisaSelectAdSetFacebook from '../SelectGroup/TecnisaSelectAdSetFacebook';
import TecnisaSelectCreativeGroupFacebook from '../SelectGroup/TecnisaSelectCreativeGroupFacebook';

const TecnisaHeaderFacebook: React.FC = () => {
  return (
    <div className="col-span-12">
      <div className="rounded-sm border border-stroke bg-white py-3 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex justify-between gap-2.5 py-3 px-6 hover:bg-gray-2 dark:hover:bg-meta-4 sm:items-center sm:justify-start">
            <div className="flex items-center gap-5.5 sm:w-5/12 xl:w-3/12">
                <div className="hidden h-14 w-full max-w-14 items-center justify-center rounded-full border border-stroke bg-gray text-black-2 dark:border-strokedark dark:bg-graydark dark:text-white sm:flex">
                    <img src="/src/images/brand/tecnisa.png" alt='Brand'></img>
                </div>
                <p className="font-medium text-black dark:text-white">
                    Facebook
                </p>
            </div>

                <div className="text-right sm:w-3/12 xl:w-3/12">
                    <TecnisaSelectEnterpriseGroupGoogle />
                    <TecnisaSelectCampaignGroupFacebook />
                </div>

                <div className="text-right sm:w-3/12 xl:w-3/12">
                    <TecnisaSelectAdSetFacebook />
                    <TecnisaSelectCreativeGroupFacebook />
                </div>

            </div>

        </div>

    </div>
  );
};

export default TecnisaHeaderFacebook;
