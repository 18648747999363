import { useState } from 'react';
import { Package } from '../../types/package';
import CheckboxTwo from '../Checkboxes/CheckboxTwo';

const packageData: Package[] = [
  {
    name: 'Item',
    price: 0.0,
    invoiceDate: `Jan 13,2023`,
    status: 'Paid',
  },
  {
    name: 'Item',
    price: 59.0,
    invoiceDate: `Jan 13,2023`,
    status: 'Paid',
  },
  {
    name: 'Item',
    price: 99.0,
    invoiceDate: `Jan 13,2023`,
    status: 'Unpaid',
  },
];

const TableNotificationTwo = () => {
  // Estado para controlar quais checkboxes estão selecionados por linha e coluna
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});

  // Função para lidar com a mudança de estado dos checkboxes
  const handleCheckboxChange = (rowIndex: number, colIndex: number) => {
    const key = `${rowIndex}-${colIndex}`;
    setCheckedItems((prev) => ({
      ...prev,
      [key]: !prev[key], // Alterna o estado do checkbox específico
    }));
  };

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                Campanha
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Email
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Desktop
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Central
              </th>
            </tr>
          </thead>
          <tbody>
            {packageData.map((packageItem, rowIndex) => (
              <tr key={rowIndex}>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <h5 className="font-medium text-black dark:text-white">
                    {packageItem.name}
                  </h5>
                </td>

                {[1, 2, 3].map((colIndex) => (
                  <td
                    key={colIndex}
                    className="border-b border-[#eee] py-5 px-4 dark:border-strokedark"
                  >
                    <div className="flex items-center space-x-3.5">
                      {/* Controle o checkbox com base no estado */}
                      <CheckboxTwo
                        isChecked={checkedItems[`${rowIndex}-${colIndex}`] || false}
                        onChange={() => handleCheckboxChange(rowIndex, colIndex)}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableNotificationTwo;
