import React, { useState } from 'react';

interface criativo {
  id: string;
  name: string;
}

const criativos: criativo[] = [
  { id: 'Visualização da sessão', name: 'Visualização da sessão' },
  { id: 'Criação de novos Criativos', name: 'Criação de novos Criativos' },
  { id: 'Uploads de Criativos', name: 'Uploads de Criativos' },
];

const CheckboxInputGroup: React.FC<{
  id: string;
  isChecked: boolean;
  onChange: () => void;
}> = ({ id, isChecked, onChange }) => (
  <div>
    <label className="relative flex cursor-pointer select-none items-center gap-2 text-sm font-medium text-black dark:text-white">
      <input
        className="sr-only"
        type="checkbox"
        name="roleSelect"
        id={id}
        checked={isChecked}
        onChange={onChange}
      />
      <span
        className={`flex h-5 w-5 items-center justify-center rounded-full border ${
          isChecked ? 'border-primary' : 'border-body'
        }`}
      >
        <span
          className={`h-2.5 w-2.5 rounded-full bg-primary ${
            isChecked ? 'flex' : 'hidden'
          }`}
        ></span>
      </span>
      {id}
    </label>
  </div>
);

const SelectOptionCriativo: React.FC = () => {
  const [selectedCriativos, setSelectedCriativos] = useState<string[]>([]);

  const handleCriativoChange = (id: string) => {
    setSelectedCriativos((prevSelectedCriativos) => {
      if (prevSelectedCriativos.includes(id)) {
        // Deselect the language if it's already selected
        return prevSelectedCriativos.filter((lang) => lang !== id);
      } else {
        // Select the language if it's not selected
        return [...prevSelectedCriativos, id];
      }
    });
  };

  return (
    <div className="mb-6">
      <h3 className="mb-4.5 block text-sm font-medium text-azul_medio dark:text-white">
        Criativos
      </h3>

      <div className="flex flex-col gap-2.5">
        {criativos.map((criativo) => (
          <CheckboxInputGroup
            key={criativo.id}
            id={criativo.id}
            isChecked={selectedCriativos.includes(criativo.id)}
            onChange={() => handleCriativoChange(criativo.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectOptionCriativo;
