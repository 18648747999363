// import { Link } from 'react-router-dom';
// import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
// import SelectGroupIdioma from '../../components/Forms/SelectGroup/SelectGroupIdioma';
// import DefaultLayout from '../../layout/DefaultLayout';
// import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';
// import userOne from "@images/user/user-01.png"
// import TableUsers from '../../components/Tables/TableUsers';
// import MmmTableSix from '../../components/MmmComponents/MmmTableSix';

// const FormUsersAndTeams = () => {
//   return (
//     <DefaultLayoutTwo>
//       {/* <Breadcrumb pageName="Form Layout" /> */}

//       <div className="grid grid-cols-10 gap-9 sm:grid-cols-1">

//           {/* <!-- Contact Form --> */}
//             <h3 className="font-renner text-sm text-grafite dark:text-white">
//                 Crie novos usuários, defina as permissões de usuário e remova usuáros da conta.
//             </h3>
//             <div className="col-span-12 xl:col-span-12">
//                 <TableUsers/>
//             </div>

//       </div>
//     </DefaultLayoutTwo>
//   );
// };

// export default FormUsersAndTeams;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalUserConfig from '../../components/Modals/ModalUserConfig';
import TableUsers from '../../components/Tables/TableUsers';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';

const FormUsersAndTeams = () => {
  const [openTab, setOpenTab] = useState(2);
  return (
    <DefaultLayoutTwo>
      {/* <Breadcrumb pageName="Form Layout" /> */}

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        {/* <!-- Contact Form --> */}
        <h3 className="font-renner text-sm text-grafite dark:text-white">
          Crie novos usuários, defina as permissões de usuário e remova usuáros
          da conta.
        </h3>

        <form action="#">
          <div className="px-6.5">
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="col-span-6 xl:col-span-6">
                <TableUsers />
              </div>
            </div>

            {/* <ModalAddUser/> */}

            <ModalUserConfig />

            <Link
              to="/forms/form-layout-add-user"
              className="inline-flex items-center justify-center rounded-md bg-virtuspurple px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
            >
              Adicionar Perfil
            </Link>
          </div>
        </form>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormUsersAndTeams;
