const FormBudgetManagement = () => {
    return (
      <div className="col-span-6 overflow-hidden rounded-sm xl:col-span-6">
        <div className="flex flex-col gap-2">
          {/* <!-- Input Fields --> */}
          <form action="#">
            <div className="p-0">
            <div>
                <h5 className="pb-10 text-xl font-semibold text-black dark:text-white">
                    Gestão de orçamento
                </h5>
            </div>
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full xl:w-1/3">
                  <label className="mb-3 block font-medium text-azul_medio dark:text-white">
                    Valor à ser investido
                  </label>
                  <input
                    type="number"
                    placeholder="R$"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-virtuspurple active:border-virtuspurple disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>
  
                <div className="w-full xl:w-1/3">
                  <label className="mb-3 block font-medium text-azul_medio dark:text-white">
                    Previsão de Leads
                  </label>
                  <input
                    type="number"
                    placeholder="R$"
                    disabled
                    className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-virtuspurple active:border-virtuspurple disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-virtuspurple dark:disabled:bg-black disabled:placeholder-virtuspurple"
                  />
                </div>
  
                <div className="w-full xl:w-1/3">
                  <label className="mb-3 block font-medium text-azul_medio dark:text-white">
                    Previsão de Vendas
                  </label>
                  <input
                    type="number"
                    placeholder="R$"
                    disabled
                    className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-virtuspurple active:border-virtuspurple disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-virtuspurple dark:disabled:bg-black disabled:placeholder-virtuspurple"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default FormBudgetManagement;
  