import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartLeadsState {
  series: number[];
}

const options: ApexOptions = {
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'donut',
  },
  // colors: ['#545DFC', '#0D2535','#FF9F40','#F4BE37'],
  colors: ['#545DFC','#F4BE37','#FF9F40','#0D2535'],
  labels: ['Campanha Facebook - Leads Topo', 'Campanha TikTok- Repescagem','Campanha Email - Leads Meio', 'Campanha Google Ads - Leads Fundo'],
  legend: {
    show: false,
    position: 'bottom',
  },

  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 300,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const ChartLeads: React.FC = () => {
  const [state, setState] = useState<ChartLeadsState>({
    series: [34, 33, 12, 26],
  });

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      series: [26, 12, 26, 33],
    }));
  };
  handleReset;

  return (
    <div className="sm:px-7.5 col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">

      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Leads por campanha
          </h5>
        </div>
        
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={options}
            series={state.series}
            type="donut"
          />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-azul"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Campanha Facebook - Leads Topo </span>
              <span> 34% </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#FF9F40]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Campanha Email - Leads Meio </span>
              <span> 12% </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#0D2535]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Campanha Google Ads - Leads Fundo </span>
              <span> 26% </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#F4BE37]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Campanha Facebook - TikTok- Repescagem </span>
              <span> 33% </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartLeads;
