import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import Settings from '../../pages/Pages/Settings';
// import SelectGroupTwo from '../Forms/SelectGroup/SelectGroupTwo';
import FormLayoutProfile from '../../pages/Form/FormLayoutProfile';
import FormLayoutAccont from '../../pages/Form/FormLayoutAccont';
import FormLayoutSecurity from '../../pages/Form/FormLayoutSecurity';
import SelectOptionCriativo from '../Forms/SelectOption/SelectOptionMarketing';
import SelectOptionCampanhas from '../Forms/SelectOption/SelectOptionCampanhas';
import SelectOptionCalendario from '../Forms/SelectOption/SelectOptionCalendario';
import SelectOptionAutomation from '../Forms/SelectOption/SelectOptionAutomation';
import SelectOptionRedesSociais from '../Forms/SelectOption/SelectOptionRedesSociais';
import SelectOptionLandingPages from '../Forms/SelectOption/SelectOptionLandingPages';


const TabAddProfile: React.FC = () => {
  const [openTab, setOpenTab] = useState(1);

  const activeClasses = 'text-virtuspurple border-virtuspurple';
  const inactiveClasses = 'border-transparent';

  return (
    <div className="rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm text-azul_escurofont-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 1 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(1)}
        >
          Marketing
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 2 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(2)}
        >
          Sales
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 3 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(3)}
        >
          Analytics
        </Link>
        <Link
          to="#"
          className={`border-b-2 py-4 text-sm font-galano_grotesque hover:text-virtuspurple md:text-base ${
            openTab === 4 ? activeClasses : inactiveClasses
          }`}
          onClick={() => setOpenTab(4)}
        >
          Conta
        </Link>
      </div>

      <div>
        <div
          className={`leading-relaxed ${openTab === 1 ? 'block' : 'hidden'}`}
        >
            <SelectOptionCriativo/>
            <SelectOptionCampanhas/>
            <SelectOptionCalendario/>
            <SelectOptionAutomation/>
            <SelectOptionRedesSociais/>
            <SelectOptionLandingPages/>
            <Link
                to="/Pages/UsersAndTeams"
                className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                    Salvar
              </Link>
        </div>
        <div
          className={`leading-relaxed ${openTab === 2 ? 'block' : 'hidden'}`}
        >
        </div>
        <div
          className={`leading-relaxed ${openTab === 3 ? 'block' : 'hidden'}`}
        >

        </div>
        <div
          className={`leading-relaxed ${openTab === 4 ? 'block' : 'hidden'}`}
        >
          
        </div>
      </div>
    </div>
  );
};

export default TabAddProfile
