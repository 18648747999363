import React from 'react';

interface ListItem {
  text: string;
}

const listItems: ListItem[] = [
  { text: 'Ferramenta 1' },
  { text: 'Ferramenta 2' },
];

const ListFerramentas: React.FC = () => {
  return (
    <div className="w-full rounded-md border border-stroke py-1 dark:border-strokedark">
      <ul className="flex flex-col">
        {listItems.map((item, index) => (
          <li
            key={index}
            className="flex items-center gap-2.5 border-b border-stroke px-5 py-3 last:border-b-0 dark:border-strokedark"
          >
            <span className="h-6.5 w-6.5 flex items-center justify-center rounded-full bg-primary text-white">
              {index + 1}
            </span>
            <span>{item.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListFerramentas;
