import axios from 'axios';
import { BACKEND_BASE_URL } from '../constants/environments';
import { User } from '../types/user';

interface LoginResponse {
  access: string;
  refresh: string;
  user: User;
}

const authInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

authInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const saveAuthData = (access: string, refresh: string, user: User) => {
  localStorage.setItem('access', access);
  localStorage.setItem('refresh', refresh);
  localStorage.setItem('user', JSON.stringify(user));
};

export const getTokenByCredentials = async (credentials: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  try {
    const response = await authInstance.post<LoginResponse>(
      '/api/auth/token/',
      credentials
    );

    const { access, refresh, user } = response.data;
    if (!access || !refresh || !user) {
      throw new Error('Invalid response from server');
    }

    saveAuthData(access, refresh, user);

    return response.data;
  } catch (error: any) {
    console.error('Login API error:', error);
    throw new Error('Login failed');
  }
};

export const getNewTokenByRefresh = async (): Promise<void> => {
  try {
    const token = localStorage.getItem('refresh');
    if (!token) throw new Error('No refresh token available');

    const response = await authInstance.post<LoginResponse>(
      '/api/auth/refresh/',
      { token }
    );
    const { access } = response.data;

    if (access) {
      localStorage.setItem('access', access);
    }
  } catch (error: any) {
    localStorage.clear();
    window.location.href = '/auth/signin';
    throw new Error('Token refresh failed');
  }
};

let retryCount = 0;
export const sendAuthorization = async (
  url: string,
  options: object
): Promise<any> => {
  try {
    const response = await authInstance(url, options);
    retryCount = 0;
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401 && retryCount < 1) {
      retryCount++;
      await getNewTokenByRefresh();
      return sendAuthorization(url, options);
    }
    throw error;
  }
};
