import React, { useEffect, useState } from 'react';
import { sendAuthorization } from '../../../api/auth';
import { BACKEND_BASE_URL } from '../../../constants/environments';

type Project = {
  id: number;
  name: string;
  owner_email: string;
  created_at: string;
};

type SelectGroupProjectBusinessProps = {
  onSelect: (projectId: number, projectDetails: Project | null) => void;
  selectedValue: string;
};

const fetchProjects = async (email: string): Promise<Project[]> => {
  const url = `${BACKEND_BASE_URL}/api/projects/?email=${email}`;

  try {
    const response = await sendAuthorization(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (Array.isArray(response)) {
      return response;
    }

    throw new Error('Resposta inesperada da API');
  } catch (error) {
    console.error('Erro ao buscar projetos:', error);
    throw new Error('Erro ao buscar projetos');
  }
};

const fetchProjectDetails = async (
  projectId: number
): Promise<Project | null> => {
  const url = `${BACKEND_BASE_URL}/api/projects/${projectId}/`;

  try {
    const response = await sendAuthorization(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.id) {
      return response;
    }

    throw new Error('Resposta inesperada da API');
  } catch (error) {
    console.error('Erro ao buscar detalhes do projeto:', error);
    return null;
  }
};

const SelectGroupProjectBusiness: React.FC<SelectGroupProjectBusinessProps> = ({
  onSelect,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const [options, setOptions] = useState<Project[]>([]);
  const [userEmail, setUserEmail] = useState<string>('');
  const [projectDetails, setProjectDetails] = useState<Project | null>(null);

  // Carregar o e-mail do usuário uma única vez, na montagem do componente.
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUserEmail(parsedUser.email);
    }
  }, []);

  // Carregar os projetos sempre que o e-mail do usuário for alterado.
  useEffect(() => {
    if (userEmail) {
      const getProjects = async () => {
        try {
          const projects = await fetchProjects(userEmail);
          setOptions(projects);
        } catch (error) {
          console.error('Erro ao carregar projetos:', error);
        }
      };

      getProjects();
    }
  }, [userEmail]);

  // Atualizar a cor do texto quando o usuário selecionar uma opção
  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  // Manipular a seleção de um projeto
  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const projectId = Number(e.target.value);
    setSelectedOption(e.target.value);

    try {
      const details = await fetchProjectDetails(projectId);
      setProjectDetails(details);
      onSelect(projectId, details); // Chama a função de callback onSelect com os detalhes do projeto
    } catch (error) {
      console.error('Erro ao carregar detalhes do projeto:', error);
    }

    changeTextColor(); // Muda a cor do texto após a seleção
  };

  return (
    <div className="mb-4.5">
      <div className="relative z-20 bg-transparent dark:bg-form-input">
        <select
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value);
            changeTextColor();
          }}
          className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
            isOptionSelected ? 'text-black dark:text-white' : ''
          }`}
        >
          {options.length === 0 ? (
            <option value="">Carregando...</option>
          ) : (
            options.map((option) => (
              <option
                key={option.id}
                value={option.id.toString()}
                className="text-body dark:text-bodydark"
              >
                {option.name}
              </option>
            ))
          )}
        </select>

        <span className="absolute right-4 top-1/2 z-30 -translate-y-1/2">
          <svg
            className="fill-current"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.8">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill=""
              />
            </g>
          </svg>
        </span>
      </div>
      {projectDetails && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">{projectDetails.name}</h3>
          <p>Email do Dono: {projectDetails.owner_email}</p>
          <p>
            Criado em: {new Date(projectDetails.created_at).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectGroupProjectBusiness;
