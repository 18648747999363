import React from 'react';
const RoiBar: React.FC = () => {
  return (
    <div className="col-span-12 overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-8">
      <div className="space-y-2.5 border-t border-stroke p-6 dark:border-strokedark md:p-6 xl:p-7.5">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Funil de resultados
          </h5>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            <p className="font-semibold text-virtuspurple dark:text-white">
              Visitantes
            </p>
          </div>

          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[8%] items-center justify-center bg-[#F4BE37] text-xs font-medium text-white">
              8%
            </div>
            <div className="absolute left-[8%] top-0 flex h-full w-[41%] items-center justify-center bg-azul text-xs font-medium text-white">
              41%
            </div>
            <div className="absolute left-[49%] top-0 flex h-full w-[38%] items-center justify-center bg-[#0D2535] text-xs font-medium text-white">
              38%
            </div>
            <div className="absolute left-[87%] top-0 flex h-full w-[13%] items-center justify-center bg-[#FF9F40] text-xs font-medium text-white">
              13%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            <p className="font-semibold text-virtuspurple dark:text-white">
              Leads
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[4%] items-center justify-center bg-[#F4BE37] text-xs font-medium text-white">
              4%
            </div>
            <div className="absolute left-[4%] top-0 flex h-full w-[28%] items-center justify-center bg-azul text-xs font-medium text-white">
              28%
            </div>
            <div className="absolute left-[32%] top-0 flex h-full w-[32%] items-center justify-center bg-[#0D2535] text-xs font-medium text-white">
              32%
            </div>
            <div className="absolute left-[64%] top-0 flex h-full w-[9%] items-center justify-center bg-[#FF9F40] text-xs font-medium text-white">
              9%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            <p className="font-semibold text-virtuspurple dark:text-white">
              Qualificados
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[3%] items-center justify-center bg-[#F4BE37] text-xs font-medium text-white">
              3%
            </div>
            <div className="absolute left-[3%] top-0 flex h-full w-[22%] items-center justify-center bg-azul text-xs font-medium text-white">
              22%
            </div>
            <div className="absolute left-[25%] top-0 flex h-full w-[28%] items-center justify-center bg-[#0D2535] text-xs font-medium text-white">
              28%
            </div>
            <div className="absolute left-[53%] top-0 flex h-full w-[6%] items-center justify-center bg-[#FF9F40] text-xs font-medium text-white">
              6%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            <p className="font-semibold text-virtuspurple dark:text-white">
              Oportunidades
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[2%] items-center justify-center bg-[#F4BE37] text-xs font-medium text-white">
              2%
            </div>
            <div className="absolute left-[2%] top-0 flex h-full w-[18%] items-center justify-center bg-azul text-xs font-medium text-white">
              18%
            </div>
            <div className="absolute left-[20%] top-0 flex h-full w-[22%] items-center justify-center bg-[#0D2535] text-xs font-medium text-white">
              22%
            </div>
            <div className="absolute left-[42%] top-0 flex h-full w-[4%] items-center justify-center bg-[#FF9F40] text-xs font-medium text-white">
              5%
            </div>
          </div>
        </div>

        <div className="items-center sm:flex">
          <div className="flex w-full max-w-42.5 items-center gap-3.5">
            <p className="font-semibold text-virtuspurple dark:text-white">
              Vendas
            </p>
          </div>
          <div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
            <div className="absolute left-0 top-0 flex h-full w-[1%] items-center justify-center bg-[#F4BE37] text-xs font-medium text-white">
              1%
            </div>
            <div className="absolute left-[1%] top-0 flex h-full w-[12%] items-center justify-center bg-azul text-xs font-medium text-white">
              12%
            </div>
            <div className="absolute left-[13%] top-0 flex h-full w-[18%] items-center justify-center bg-[#0D2535] text-xs font-medium text-white">
              18%
            </div>
            <div className="absolute left-[31%] top-0 flex h-full w-[4%] items-center justify-center bg-[#FF9F40] text-xs font-medium text-white">
              4%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoiBar;
