import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import CardsItemThree from '../../components/Cards/CardsItemThree';
import SelectGroupAgentCategory from '../../components/Forms/SelectGroup/SelectGroupAgentCategoy';
import SelectGroupAgentStatus from '../../components/Forms/SelectGroup/SelectGroupAgentStatus';
import DefaultLayout from '../../layout/DefaultLayout';

type Agent = {
  name: string;
  category: string;
  status: string;
  description: string;
  projectId: number;
};

const fetchAgents = async (projectId: number): Promise<Agent[]> => {
  const all_agents = [
    {
      projectId: 1,
      name: 'Agente de conversa',
      category: 'Marketing',
      status: 'Em uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 1,
      name: 'Agente de chamada',
      category: 'Comercial',
      status: 'Sem uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 1,
      name: 'Agente de remarketing',
      category: 'Suporte ao Cliente',
      status: 'Em uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 1,
      name: 'Agente de apresentação',
      category: 'Recursos Humanos',
      status: 'Sem uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 1,
      name: 'Agente de Email',
      category: 'Atendimento',
      status: 'Em uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 2,
      name: 'Agente de Chat',
      category: 'Finanças',
      status: 'Sem uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 2,
      name: 'Agente de RH',
      category: 'Personalizado',
      status: 'Em uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 2,
      name: 'Agente Financeiro',
      category: 'Recursos Humanos',
      status: 'Sem uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 2,
      name: 'Agente de WhatsApp',
      category: 'Marketing',
      status: 'Em uso',
      description: 'Descrição de uso do agente...',
    },
    {
      projectId: 2,
      name: 'Agente de Triagem de Leads',
      category: 'Comercial',
      status: 'Sem uso',
      description: 'Descrição de uso do agente...',
    },
  ];

  return all_agents.filter((obj) => obj.projectId === projectId);

  // const url = `${BACKEND_BASE_URL}/api/agents/?project_id=${projectId}`;

  // try {
  //   const response = await sendAuthorization(url, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });

  //   if (Array.isArray(response)) {
  //     return response;
  //   }

  //   throw new Error('Resposta inesperada da API');
  // } catch (error) {
  //   console.error('Erro ao buscar agentes:', error);
  //   setError('Erro ao buscar agentes');
  //   throw new Error('Erro ao buscar agentes');
  // }
};

const Agents: React.FC = () => {
  const [agentsData, setAgentsData] = useState<Agent[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    const getAgents = async () => {
      try {
        const agents = await fetchAgents(1);
        setAgentsData(agents);
      } catch (error) {
        console.error('Erro ao carregar agentes:', error);
      }
    };

    getAgents();
  }, []);

  const filteredData = useMemo(() => {
    return agentsData.filter(
      (agent) =>
        agent.name.toLowerCase().includes(searchName.toLowerCase()) &&
        (searchCategory === '' || agent.category === searchCategory) &&
        (searchStatus === '' || agent.status === searchStatus)
    );
  }, [agentsData, searchName, searchCategory, searchStatus]);

  const paginatedData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Agentes" />

      <div className="mb-5 grid grid-cols-1 items-center gap-4 sm:grid-cols-3 lg:grid-cols-4">
        <input
          type="text"
          placeholder="Pesquisar por name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="relative z-20 mt-4 w-full appearance-none rounded border border-stroke bg-white px-5 py-3 outline-none transition focus:border-primary active:border-primary dark:border-grafite_ dark:bg-form-input dark:focus:border-grafite_"
        />

        <SelectGroupAgentCategory
          selectedOption={searchCategory}
          onCategoryChange={(value: string) => setSearchCategory(value)}
        />

        <SelectGroupAgentStatus
          selectedOption={searchStatus}
          onStatusChange={(value: string) => setSearchStatus(value)}
        />

        <div className="mt-4 flex justify-start">
          <Link
            to="/dashboard/Criação de Agente"
            className="rounded-md bg-virtuspurple px-9 py-3 font-medium text-white hover:bg-opacity-90"
          >
            + Criar Agente
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-3">
        {paginatedData.map((agent, index) => (
          <CardsItemThree
            key={index}
            cardTitle={agent.name}
            cardStatus={agent.status}
            cardCategory={agent.category}
            cardContent={agent.description}
          />
        ))}
      </div>

      <div className="mt-5 flex items-center justify-between">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="rounded-md border p-2"
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="rounded-md border p-2"
        >
          Próxima
        </button>
      </div>
    </DefaultLayout>
  );
};

export default Agents;
