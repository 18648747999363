import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute: React.FC = () => {
	if (localStorage.getItem('user')) {
		return <Outlet />;
	} else {
		return <Navigate to="/auth/signin" />;
	}
};


export default ProtectedRoute;