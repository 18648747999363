import React from 'react';
import AccordionOne from '../../components/Accordions/AccordionOne';
import AccordionTwo from '../../components/Accordions/AccordionTwo';
import AccordionThree from '../../components/Accordions/AccordionThree';
import AccordionFour from '../../components/Accordions/AccordionFour';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DefaultLayoutTwo from '../../layout/DefaultLayoutTwo';
import AccordionItemFour from '../../components/Accordions/AccordionItemFour';

const FormLayoutNotification: React.FC = () => {
  return (
    <DefaultLayoutTwo>
      <div className="flex flex-col gap-7.5">
        <h3 className="font-renner text-sm text-grafite dark:text-white">
            Confiigure como prefere receber suas notificações da Vistus. Não é possivel cancelar a assinatura de emails importantes sobre sua conta, como atualizações de status e cobrança.
        </h3>
        <AccordionThree/>
        <AccordionFour/>
      </div>
    </DefaultLayoutTwo>
  );
};

export default FormLayoutNotification;