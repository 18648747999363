import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import SelectGroupOne from '../../components/Forms/SelectGroup/SelectGroupOne';
import SelectGroupProfile from '../../components/Forms/SelectGroup/SelectGroupProfile';
import SelectGroupProject from '../../components/Forms/SelectGroup/SelectGroupProject';
import DefaultLayout from '../../layout/DefaultLayout';

const FormLayoutAddUser = () => {
  return (
    <DefaultLayout>
      <Breadcrumb pageName="Adicionar usuário" />

      <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
        <div className="flex flex-col gap-9">
          {/* <!-- Contact Form --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Etapa 1 de 2
              </h3>
              <p>
                Insira os dados do usuário e quais projetos ele terá acesso.
              </p>
            </div>
            <form action="#">
              <div className="p-6.5">
                <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">

                  <div className="w-full xl:w-1/2">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Last name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your last name"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                </div>

                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Email <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Insira"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>

                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Nome <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Insira"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>

                <SelectGroupProfile />

                <SelectGroupProject />

                {/* Botões */}
            <div className="-mx-3 flex flex-wrap gap-y-4">
              <div className="2xsm:w-1/2 w-full px-3">
                <button
                  type="button" // Previne comportamento submit
                  className="inline-flex items-center justify-center rounded-md bg-meta-1 py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Cancelar
                </button>
              </div>
              <div className="2xsm:w-1/2 w-full px-3">
                <button
                  type="submit" // Ainda envia os dados, mas com prevenção do recarregamento
                  className="inline-flex items-center justify-center rounded-md bg-virtuspurple py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Enviar Convite
                </button>
              </div>
            </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default FormLayoutAddUser;
