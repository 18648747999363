import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartTenState {
  series: {
    name: string;
    data: number[];
  }[];
}

const DmaChartVehicleFinancing: React.FC = () => {
  const [state, setState] = useState<ChartTenState>({
    series: [
      {
        name: 'Sim',
        data: [108],
      },
      {
        name: 'Não',
        data: [55],
      },
      {
        name: 'Depende',
        data: [86],
      },
    ],
  });

  // Update the state
  const updateState = () => {
    setState((prevState) => ({
      ...prevState,
      // Update the desired properties
    }));
  };
  updateState;

  const options: ApexOptions = {
    colors: ['#13C296', '#DC3545', '#F2994A'],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 318,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        // endingShape: 'rounded',
        borderRadius: 1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [''],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Satoshi',
      fontSize: '16px',
      fontWeight: 500,
      offsetY: -5,

      markers: {
        radius: 99,
        width: 16,
        height: 16,
      },
    },
    // yaxis: {
    //   title: false,
    // },
    grid: {
      strokeDashArray: 7,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      x: {
        show: false,
      },
      // y: {
      //   formatter: function (val) {
      //     return val;
      //   },
      // },
    },
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="xl:p-7.5 flex flex-col gap-2 p-4 sm:flex-row sm:justify-between md:p-6">
        <div>
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Financiamento
          </h2>
        </div>
        
      </div>

      <div className="px-7.5">
        <div id="chartTen" className="-ml-5">
          <ReactApexChart
            options={options}
            series={state.series}
            type="bar"
            height={585}
          />
        </div>
      </div>
    </div>
  );
};

export default DmaChartVehicleFinancing;
